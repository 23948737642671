function validateEmail(email) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
}

// Beliebige weitere Funktionen...

module.exports = {
    validateEmail,
    // Andere Funktionen exportieren...
};
