<template>
  <div class="s3m-ap_list_field">
    <div v-on:click="showApList = !showApList"><span v-if="showApList">Schließen</span><span v-else>Anzeigen</span> ( {{ aplist.length }} )</div>
    <div>
      <!--
      <ul
          class="form-control"
          v-if="showApList"
      >
        <li v-for="item in aplist" :key="item._id">{{ item.P_Kunde_Obj.Vorname + " " + item.P_Kunde_Obj.Nachname1 + " - " + (item.P_Kunde_Obj.InternetUser != null ? item.P_Kunde_Obj.InternetUser : " ") + " - " + item.TelefonNr}} <v-btn style="margin-bottom: 0px;" v-on:click="edit(item)" color="primary">
          Bearbeiten
        </v-btn>
          <v-btn class="s3m_delete_item" style="" v-on:click="deleteAP(item)" color="primary">
          Löschen
        </v-btn></li>
      </ul>-->
      <v-simple-table v-if="showApList" class="form-control">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">InternetUser</th>
            <th class="text-left">TelefonNr</th>
            <th class="text-left">Adresse</th>
            <th class="text-left">Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in aplist" :key="item._id">
            <td>{{ item.P_Kunde_Name || (item.P_Kunde_Obj && item.P_Kunde_Obj.KurzbezeichnungExtern) || (item.P_Kunde_Obj && (item.P_Kunde_Obj.Adresse11 + " " + item.P_Kunde_Obj.Adresse12)) || '' }}</td>            <td @click="openLoginUser(item)">{{ item.InternetUser != null ? item.InternetUser : " " }}</td>
            <td>{{ item.TelefonNr }}</td>
            <td>{{ item.P_Kunde_Obj ? (item.P_Kunde_Obj.Strasse ? item.P_Kunde_Obj.Strasse : item.P_Kunde_Obj.Postfach) : ""}}<br/>{{item.P_Kunde_Obj ? (item.P_Kunde_Obj.PLZ_Str ? item.P_Kunde_Obj.PLZ_Str : item.P_Kunde_Obj.PLZ_PF) : ""}}<br>{{item.P_Kunde_Obj ? item.P_Kunde_Obj.Ort : "" }}</td>
            <td>
              <v-btn style="margin-bottom: 0px;" v-on:click="edit(item)" color="primary">
                Bearbeiten
              </v-btn>
              <v-btn class="s3m_delete_item" v-on:click="deleteAP(item)" color="primary">
                Löschen
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-btn v-if="this.$store.state.copyUser != null" style="margin-top:40px;margin-bottom: 40px;" v-on:click="create" color="primary">
        Neuen Ansprechpartner aus Zwischenablage hinzufügen
      </v-btn>
      <v-btn style="margin-top:40px;margin-bottom: 40px;" v-on:click="chooseAP" color="primary">
        Neuen Ansprechpartner hinzufügen
      </v-btn>
    </div>
    <generated-form ref="generatedForm1"/>
    <confirm-dialog ref="confirmDelete"/>
    <user-edit-dialog ref="userEditDialog"/>
    <template>
      <v-dialog content-class="s3m_user_list_dialog" v-model="userListDialog">
        <UserList listType="selectAP" @closeDialog="userListDialog=false" @userSelected="handleUserSelected" />
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { abstractField } from "vue-form-generator";
import GeneratedForm from "@/components/GeneratedForm";
import modelService from "@/services/model.service";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import UserList from "@/views/UserList.vue";
import userService from "@/services/user.service";
import APIService from "@/services/APIService";
import UserEditDialog from "@/components/UserEditDialog.vue";

export default {
  name: "APListField",
  components: {
    UserEditDialog,
    UserList,
    ConfirmDialog,
    GeneratedForm,
  },
  data: () => ({
    aplist: [],
    showApList: false,
    userListDialog: false
  }),
  mixins: [ abstractField ],
  methods: {
    create(){
      console.log("create ap");
      this.$refs.generatedForm1.show("kundeap", null)
    },
    edit (item) {
      console.log("open model");
      console.log(item);
      this.$store.state.copyUser = null;
      this.$refs.generatedForm1.show("kundeap", item)
    },
    async deleteAP (item) {
      if(!item.id) item.id = item._id;
      console.log("delete AP");
      console.log(item.id);
      console.log(item);
      console.log(this.value);

      this.$refs.confirmDelete.show({
        title: 'Ansprechpartner entfernen',
        text: `Wollen sie <b>${item.P_Kunde_Obj.Vorname} ${item.P_Kunde_Obj.Nachname1}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(async () => {
        let res = await modelService.deleteModel(item, "kundeap");
        console.log(res);
        if(item.InternetUser !== "")
          await userService.deleteLoginUserByMail(item.InternetUser);
        if(!this.value){
          return;
        }else {
          for (let i = 0; i < this.value.length; i++) {
            console.log(this.value[i]);
            if (this.value[i].id === item.id) {
              this.value.splice(i, 1);
              this.aplist.splice(i, 1);
              break;
            }
          }
          this.getAPs();
        }
      })
    },
    async openLoginUser(item) {
      let user = await APIService.getUserByEmail(item.InternetUser);

      if (user === undefined) {
        this.$refs.confirmDelete.show({
          title: 'Login-User erstellen',
          text: `Der Login-User mit der E-Mail Adresse ${item.InternetUser} ist nicht vorhanden. Soll er erstellt werden?`,
          confirm: 'Ja',
        }).then(async () => {
          userService.createLoginUser(item, item.id)
              .then(async () => {
                user = await APIService.getUserByEmail(item.InternetUser);
                this.$refs.userEditDialog.show(user)
              })
              .catch(error => console.log(error));
        });
      } else {
        this.$refs.userEditDialog.show(user);
      }
    },
    gotoKunde(){
      this.$router.push('/admin/users?filter=KundeTyp&value=P');
    },
    chooseAP(){
      this.userListDialog = true;
    },
    handleUserSelected(user){
      console.log(user);
      this.$store.state.copyUser = user;
      this.userListDialog = false;
      this.create();
    },
    async getAPs(){

      if(this.$store.state.copyUser != null){
        console.log(this.$store.state.copyUser);
      }
      console.log("getAPs");
      if(!this.value){
        console.log("no value");
        return;
      }

      console.log(this.value);
      this.aplist = this.value;

      if(this.aplist == null || this.aplist === "undefined") {
        this.aplist = [];
      }

      /*
      if(this.aplist == null || this.aplist == "undefined"){
        this.aplist = [];
        for(let i=0; i<this.value.length; i++){
          console.log(this.value[i])
          let user = await modelService.getModel("kunde", null, this.value[i].P_Kunde
          );
          this.value[i].user = user;
          console.log(this.value[i].user);
          this.value[i].test = "123";
          this.aplist.push(this.value[i])
        }
      }*/
    }
  },
   mounted() {
     console.log("mounted aps");
     this.$root.$off("updatekundeapList");
     this.$root.$on('updatekundeapList', (msg) => {
       console.log("update aplist "+msg+"1");

     });
      this.getAPs();
    }
};
</script>
