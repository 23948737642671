<template>
  <div class="s3m-generated_form">
  <v-row justify="center" class="s3m-generated_form">
    <OverlayDialog
        v-model="showDialog"
        persistent
        max-width="800px"
        style="z-index:100"
        v-on:save="this.saveForm"
        v-on:close="this.close"
        class="s3m-gf_overlay_dialog"
        content-class="s3m-gf_overlay_dialog"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{this.headline}}</span>
        </v-card-title>
        <v-card-subtitle class="subheadline">{{this.subheader}}</v-card-subtitle>

        <vue-form-generator :class="gfClass" :key="updateCounter" :schema="schema" :model="model" :options="formOptions" ></vue-form-generator>

      </v-card>
      <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
      <snackbar ref="snackbar"></snackbar>
    </OverlayDialog>
  </v-row>
  </div>
</template>

<script>
import VueFormGenerator from 'vue-form-generator'
import APIService_2 from "@/services/APIService_2";
import ModelService from '@/services/model.service'
import OverlayDialog from "@/components/OverlayDialog.vue";
import Snackbar from "@/components/Snackbar.vue";
import UserService from "@/services/user.service";
import utils from "@/utils/utils";

export default {
  name: "GeneratedForm",
  data: () => ({
    gfClass: "s3m-generated_form_",
    isRegistration: false,
    registrationType: "",
    showDialog: false,
    showLoader: false,
    headline: "",
    subheader: "",
    type: "",
    updateParent: true,
    id: "",
    updateCounter: 0,
    model: {},
    oldModel: {},
    schema: {},
    snackbarError: {
      text: 'Beim Speichern des Datensatzes ist etwas schiefgegangen',
      icon: 'mdi-alert-circle',
      color: 'error'
    },
    snackbarErrorKunde: {
      text: 'Leider konnte der Kunde nicht angelegt werden. Möglicherweise ist die E-Mail Adresse bereits vorhanden.',
      icon: 'mdi-alert-circle',
      color: 'error'
    },
    snackbarErrorPassword: {
      text: 'Bitte prüfen sie ob die Passwörter den Kriterien entsprechen und übereinstimmen.',
      icon: 'mdi-alert-circle',
      color: 'error'
    },
    snackbarErrorEmail: {
      text: 'Die E-Mail Adresse scheint nicht korrekt zu sein.',
      icon: 'mdi-alert-circle',
      color: 'error'
    },
    snackbarErrorInternetUser: {
      text: 'Die E-Mail Adresse für den Login (InternetUser) scheint nicht korrekt zu sein.',
      icon: 'mdi-alert-circle',
      color: 'error'
    },
    snackbarErrorRequired: {
      text: 'Bitte füllen Sie alle erforderlichen Felder aus.',
      icon: 'mdi-alert-circle',
      color: 'error'
    },
    snackbarSuccess: {
      text: 'Die Daten wurden erfolgreich gespeichert.',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    formOptions: {
      validateAfterLoad: true,
      validateAfterChanged: true,
      validateAsync: true
    }
  }),
  components: {
    Snackbar,
    OverlayDialog,
    "vue-form-generator": VueFormGenerator.component
  },
  methods: {
    async show(type, model, id = "", modelType = "", smp = true, isRegistration = false) {

      this.isRegistration = isRegistration;
      if(this.$route.query.type && this.$route.query.type !== ""){
        this.registrationType = this.$route.query.type;
        console.log(this.registrationType);
      }
      this.showLoader = true;
      this.gfClass = "s3m-generated_form_"+type;
      this.type = type;
      this.id = id;
      this.headline = this.type;

      /*
      * schema
      * */
      let schema_type = type;
      if(modelType !== ""){
        schema_type = type + "_" + modelType;
      }

      switch(modelType){
        case "R":
        case "RT":{
          this.updateParent = false;
          break;
        }
        case "RF": {
          //schema_type = "kunde_R";

          modelType = "F";
          this.updateParent = false;
          break;
        }

      }

      this.schema = await APIService_2.getTableSchema(schema_type);

      if(model == null && id === ""){
        this.model = VueFormGenerator.schema.createDefaultObject(this.schema, {
          created: new Date().valueOf()
        });
        this.model.schema = this.schema;
        this.model.schemaType = schema_type;
        if(type === "kunde"){
          this.model.KundeTyp = modelType;
          this.model = await UserService.getContext(this.model, true);
        }else{
          this.model = await ModelService.getContext(this.model, type);
        }

      }else if(id !== "" && smp){
        let testmode = false;
        this.model.schema = this.schema;
        this.model = await ModelService.getModel(type, this.schema, id, testmode);
        this.model = await ModelService.getContext(this.model, type);
      }else{
        this.model = model;
        this.model.schema = this.schema;
        this.model = await ModelService.getContext(this.model, type);
      }

      this.model.schemaType = schema_type;

      this.$store.state.currentModel = this.model;

      this.showLoader = false;

      console.log(this.model);

      switch(type){
        case "kunde":{
          this.headline = this.model.KundenNr+" - "+this.model.KurzbezeichnungExtern;
          if(isRegistration){
            this.headline = "Registrierung Ausbildungsnavigator";
            if(this.registrationType === "ms"){
              this.subheader = "Mit den folgenden Dateneingaben registrieren Sie Ihren Betrieb kostenfrei für die Betriebesuche auf der o.a. Website. Voraussetzung ist die Mitgliedschaft in einer Innung/Verband (in Baden-Württemberg nur SAF).";
              this.headline = "Registrierung Betrieb für mission-stuckateur.de (Ausbildungsnavigator)"
            }
          }
          this.model.accessType = modelType;
          break;
        }
        case "kundeap": {
          this.headline = "Ansprechpartner";
        }
      }

      // eslint-disable-next-line no-unused-vars

      this.oldModel = { ...this.model }
      this.showDialog = true
      this.updateCounter++;
    },
    close () {
      this.showDialog = false
      this.model = {}
    },
    async saveForm(){
      console.log(this.model);
      this.showLoader = true;
      if(this.isRegistration){
        this.model.isRegistration = this.isRegistration;
        this.model.registrationType = this.registrationType;

        let passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        let forbiddenCharacters = /[:/#!]/g;

        if(!utils.validateEmail(this.model.email)){
          this.$refs.snackbar.show(this.snackbarErrorEmail);
          this.showLoader = false;
          return;
        }

        console.log(this.model.berufsgruppe.Berufsgruppe);
        let requiredSet = true;
        for (let group of this.model.schema.groups) {
          for (let field of group.fields) {
            // Check for "visible" and "required" validation rules
            if (field.visible === true && field.required === true) {
              // Accessing nested properties using field.model (expecting something like 'berufsgruppe.Berufsgruppe')
              let keys = field.model.split(".");
              let fieldValue = this.model;
              for(let key of keys) {
                fieldValue = fieldValue[key];
              }

              // If the field value is not set or it's an empty string, show error.
              if (!fieldValue || fieldValue.length < 1) {
                console.log("fehlt "+field.model);
                requiredSet = false;
              }
            }
          }
        }

        if (!requiredSet) {
          this.$refs.snackbar.show(this.snackbarErrorRequired);
          this.showLoader = false;
          return;
        }

        if(this.model.password !== this.model.password2 || !passwordPattern.test(this.model.password) || forbiddenCharacters.test(this.model.password)){
          this.$refs.snackbar.show(this.snackbarErrorPassword);
          this.showLoader = false;
          return;
        }
      }

      let res = await ModelService.saveModel(this.model, this.type, this.id, this.oldModel);
      if(res === false || (res.status !== undefined && res.status === false)){
        if(this.type === "kunde" || this.type === "kundeap"){
          console.log(res.error);
          if(res.error !== undefined && res.error === "invalid_email_internetuser") {
            this.$refs.snackbar.show(this.snackbarErrorInternetUser);
          }else if(res.error !== undefined && res.error === "invalid_email_contact"){
            this.$refs.snackbar.show(this.snackbarErrorEmail);
          }else{
            this.$refs.snackbar.show(this.snackbarErrorKunde);
          }
        }else{
          this.$refs.snackbar.show(this.snackbarError);
        }
        this.showLoader = false;
        return;
      }else{
        this.$refs.snackbar.show(this.snackbarSuccess);
      }
      if(this.model && this.updateParent){
        this.$root.$emit('update'+this.type+'List', this.type);
      }
      this.showLoader = false;
      if(this.isRegistration){
        console.log("redirect after registration");
        this.$router.push({name: 'ssoLogin'})
      }
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
