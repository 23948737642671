<template>
  <div class="s3m-snackbar">
    <v-snackbar
      v-model="showSnackbar"
      :color="color"
      multi-line="multi-line"
      timeout="4000"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>{{ text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Snackbar',
  data () {
    return {
      showSnackbar: false,
      text: '',
      color: '',
      icon: ''
    }
  },
  methods: {
    show (props) {
      this.text = props.text
      this.color = props.color
      this.icon = props.icon
      this.showSnackbar = true
    }
  }
}
</script>

<style scoped>

</style>
