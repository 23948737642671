<template>
  <div class="s3m-user_edit">
    <v-row justify="center">
      <v-dialog
          v-model="showDialog"
          persistent
          max-width="600px"
          content-class="s3m-user_edit_dialog"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ (this.editUser.firstName ? this.editUser.firstName : "") + " " + (this.editUser.lastName ? this.editUser.lastName : "") + " ("+ (this.editUser.email ? this.editUser.email : "") +")" }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="userForm" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        v-model="editUser.password"
                        label="Passwort"
                        hint="Falls kein Passwort vergeben wird, wird dieses automatisch generiert."
                        required
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        @blur="showPassword=false"
                        :rules="passwordRules"
                    ></v-text-field>
                    <div class="s3m-padding">Das Passwort muss mindestens 8 Zeichen lang sein und sowohl einen Buchstaben als auch eine Zahl enthalten. Außerdem dürfen folgende Zeichen nicht verwendet werden: :  /  # !</div>

                  </v-col>
                  <v-col v-if="hasRole('admin')" cols="12">
                    <v-select v-model="editUser.role" :items="roles" label="Rolle"></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="close"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="saveUser"
                class="s3m_button_login_edit"
                :disabled="loading" :class="{ 'cursor-not-allowed': loading }"
            >
              {{ user.id ? 'Speichern' : 'Hinzufügen'}}
            </v-btn>
            <v-btn class="s3m_button_login_edit" v-if="this.$store.state.user.role === 'admin'" color="primary" @click="navigateToSelfService">User bearbeiten</v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
      <confirm-dialog ref="confirmDialog"/>
      <snackbar ref="snackbar"></snackbar>
    </v-row>
  </div>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/Snackbar'
import store from "@/store";
import {mapGetters, mapState} from "vuex";
import userService from "@/services/user.service";
export default {
  name: 'UserEditDialog',
  components: { Snackbar, ConfirmDialog },
  data: () => ({
    isEmailTaken: false,
    isLoginTaken: false,
    oldEmail: undefined,
    oldUser : null,
    passwordRules: [
      value => (!value || value  && value.length >= 8) || `Das Passwort muss mindestens 8 Zeichen lang sein.x${!!value}x`,
      value => (!value || value && !!value.match(/\d/) && !!value.match(/[a-zA-Z]/)) || 'Das Passwort muss mindestens einen Buchstaben und eine Zahl enthalten',
      value => (!value || value && !value.includes('!') && !value.includes('/')  && !value.includes('#')) || 'Das Passwort darf die Zeichen !, /, # nicht enthalten'
    ],
    showDialog: false,
    showPassword: false,
    snackbarSuccess: {
      text: 'Benutzer erfolgreich gespeichert',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    snackbarErrorPassword: {
      text: 'Das Passwort erfüllt nicht die Mindestvoraussetzungen.',
      icon: 'mdi-close',
      color: 'error'
    },
    roles: [
      { text: 'Administrator', value: 'admin'},
      //{ text: 'Vertrieb', value: 'sale' },
      { text: 'Autor', value: 'author' },
      //{ text: 'Lizenzmanager', value: 'licenceManager' },
      { text: 'Benutzer', value: 'user' },
    ],
    editUser: {}
  }),
  methods: {
    async show (user) {
      this.$store.commit('setLoading', true);
      this.editUser = { ...user, role: user ? user.role : 'user' }

      this.oldEmail = user?.email
      this.showDialog = true

      this.oldUser = {...user}

      try {
        this.editUser.kundeObj = await APIService.getKundeOrAPWithInternetUser(user.email);

        let sammelgruppenPromise = userService.getAllSammelgruppenForKunde(this.editUser.kundeObj.id, this.editUser.kundeObj.KundeTyp);
        let gruppenPromise = userService.getAllGruppenForKunde(this.editUser.kundeObj.id, this.editUser.kundeObj.KundeTyp);

        let [sammelgruppen, gruppen] = await Promise.all([sammelgruppenPromise, gruppenPromise]);

        this.editUser.kundeObj.sammelgruppen = sammelgruppen;
        this.editUser.kundeObj.gruppen = gruppen;

      } catch (e) {
        console.error(e);
      }
      this.$store.commit('setLoading', false);
    },
    close () {
      this.showDialog = false
      this.editUser = {}
      this.$refs.userForm.resetValidation()
    },
    navigateToSelfService(){
      console.log("open "+this.editUser.email);
      this.$store.commit('updateEditUser', this.editUser)
      this.$router.push('/admin/settings');
    },
    async saveUser () {
      if (!this.$refs.userForm.validate()) {
        console.log("valiation not passed");
        this.$refs.snackbar.show(this.snackbarErrorPassword)
        return
      }
      if((!this.editUser.id || this.isEmailChanged()) && await APIService.isEmailTaken(this.editUser.email)) {

        this.isEmailTaken = true
        return
      }
      this.$store.commit('setLoading', true);
      if (this.editUser.id) {
        APIService.updateUser(this.editUser).then(() => {
          store.dispatch('fetchUsers')
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      } else {
        APIService.saveUser(this.editUser).then(() => {
          store.dispatch('fetchUsers')
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      }
      //sync user role with wordpress
      if(this.oldUser.role !== this.editUser.role){
        await userService.setUserRole(this.editUser);
      }
      if(this.editUser.password !== ""){
        await userService.setUserPassword(this.editUser);
      }
      this.$store.commit('setLoading', false);
    },
    isEmailChanged() {
      return this.editUser.email !== this.oldEmail
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
    emailRules() {
      return [
        value => !!value || 'Email erforderlich.',
        !this.isEmailTaken || 'Die Email-Adresse ist bereits vergeben.'
      ]
    },
  },
  computed: {
    ...mapGetters(['hasRole']),
    ...mapState(['user']),
    loading() {
      return this.$store.state.loading;
    }
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    },
  }
}
</script>

<style scoped>

</style>
