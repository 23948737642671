<template>
  <v-app class="s3m-ssologin">
    <img class="s3m_header_img" src="https://admin.stuck-komzet.de/ext/img/Ausbau_Akademie_Claim_rechts_s.jpg" alt="">
    <v-card class="mt-5 mx-auto">
      <v-card-title>
        <h1>Ihr zentrales Login für das Branchenzentrum Ausbau und Fassade</h1>
      </v-card-title>
    <v-card-text>
      <p>
      Mit unserem neuen Anmeldungssystem haben Sie einen zentralen Zugang zu unseren Online-Angeboten.<br>
      So können Sie nach Anmeldung mit demselben Zugang auf unsere System zugreifen<br>
      Das gilt für diese Portale:<br>
      </p>
      <p>
        <a target="_blank" href="https://www.stuck-verband.de">https://www.stuck-verband.de</a>
        <a target="_blank" href="https://www.stuck-komzet.de">https://www.stuck-komzet.de</a>
        <a target="_blank" href="https://www.ausbau-akademie.de">https://www.ausbau-akademie.de</a>
      </p>
      <p>
      Sowie die AuFBau App <br><br>
      </p>
      <p>
      <b>Wichtig:</b> Aufgrund der Systemumstellung müssen Sie sich <u>bei der ersten Anmeldung </u> einmalig ein neues Passwort vergeben.<br>
      Nutzen Sie dazu bitte den Link "Passwort vergessen".<br>
      </p>
      <div>
      <h2>Nutzen Sie Ihre Vorteile als SAF-Mitglied!</h2>
        <p>
      Im Shop der Ausbau-Akademie sind für SAF-Mitglieder Sonderpreise hinterlegt. Diese werden nach Anmeldung <u>mit dem Firmenzugang</u> sichtbar. <br>
      Daher melden Sie sich vor Bestellung unbedingt an, um diese Mitgliedervorteile zu nutzen.
        </p>
      </div>
      <div>
      <h2>Sie haben Fragen zu Ihrem Zugang?</h2>
        <p>
          Sollten Sie Fragen zu Ihrem Zugang haben, wenden Sie sich bitte direkt an uns:<br>
      Telefon: 07152 / 30 550 – 200<br>
      E-Mail: info.komzet@bz-af.de
        </p>
      </div>
      <p>
      <b>Herzliche Grüße aus Rutesheim<br>
        Ihr Branchenzentrum-Team</b>
      </p>
    </v-card-text>
    </v-card>
    <v-card width="400px" class="mt-5 mx-auto">
      <v-card-title>
        <h1>Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form value>
          <v-text-field
              label="E-Mail Adresse"
              prepend-icon="mdi-account-circle"
              v-model="email"
          />
          <v-text-field
              label="Password"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="password"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            color="info"
            class="mx-auto px-4"
            @click="doLogin"
        >
          Login
        </v-btn>
      </v-card-actions>
      <a href="https://veranstaltungen.ausbau-akademie.de/app/forgot-password">Passwort vergessen / neu vergeben</a>
    </v-card>
    <v-snackbar
        v-model="loginFail"
        color="error"
        multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{loginFailMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </v-app>
</template>
<script>
import APIService from "@/services/APIService";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      showLogin: false,
      loginFail: false,
      loginFailMessage: '',
      localLogin: false
    }
  },
  methods: {
    ...mapMutations(['updateUser', 'updateManagedUsers']),
    ...mapActions(['logout','login','fetchManagedUsers', 'fetchKunde', 'fetchSammelgruppen', 'fetchGruppen']),
    ...mapGetters(['isLoggedIn']),
    doLogin() {
      console.log("Loggin called")
      if(this.localLogin){
        this.loginLocal();
      }
      APIService.login(this.email, this.password).then((userData) => {
        console.log("Response", userData)
        this.updateUser(userData)
        this.$store.commit('clearEditUser');
        const { client_id, scope, redirect_uri, state} = this.$route.query
        APIService.getAuthCode(client_id, redirect_uri, scope).then((code) => {
          window.location.href=  `${redirect_uri}?code=${code}${state ? `&state=${state}` : ''}`
        });
      }).catch(error => {
        console.log("Login failed", error)
        this.logout()
        this.loginFailMessage = 'Falscher Benutzername oder Passwort.'
        this.loginFail = true
        this.password = ''
      })
    },
    loginLocal () {
      APIService.login(this.email, this.password).then(async (data) => {

        console.log(data);

        data.user.origMail = this.email;
        this.updateUser(data)

        this.fetchManagedUsers().then()
        await this.fetchKunde(this.email).then()
        //await this.fetchSammelgruppen().then()
        await this.fetchGruppen().then()
        this.$router.push({name: 'admin'}).catch(() => {
        });
      }).catch(error => {
        console.log("Login failed", error)
        this.logout()
        this.loginFailMessage = 'Falscher Benutzername oder Passwort.'
        this.loginFail = true
        this.password = ''
        Promise.reject()
      })
    }
  },
  computed: {
    ...mapGetters(['accessToken']),
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh,
    }],
  },
  mounted() {
    if(this.localLogin){
      console.log("local login");
      if(this.isLoggedIn()){
        window.location.href= "/admin";
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    document.title = "Zentral-Login Branchenzentrum Ausbau und Fassade";
    const { response_type, client_id, scope, redirect_uri, state} = to.query

    if(response_type !== 'code' || !client_id || !redirect_uri || !scope || !scope.includes('openid')) {
      console.log("login local");
      next(vm => {
        vm.localLogin = true;
      });

    }

    APIService.getAuthCode(client_id, redirect_uri, scope).then( code => {
      window.location.href= `${redirect_uri}?code=${code}${state ? `&state=${state}` : ''}`
    }).catch(error => {
      if(error.response.status === 401) {
        next()
        return
      }
      next({name: 'error', params: { message: error.response.data.message }})
    })
  }
}
</script>

<style scoped>

</style>
