<template>
  <div class="s3m-login">
    <v-card width="400px" class="mt-5 mx-auto">
      <v-card-title>
        <h1>Users Updated</h1>
      </v-card-title>
      <v-divider></v-divider>
      <v-card>
        <v-col>
        <v-row>
        <v-btn
          color="red"
          class="mx-auto px-4"
          @click="update('F')"
        >
          Update All InternetUsers for Firmen
        </v-btn>
        </v-row>
        <v-row>
        <v-btn
            color="blue"
            class="mx-auto px-4"
            @click="update('P')"
        >
          Update All InternetUsers for Personen
        </v-btn>
        </v-row>
        <v-row>
        <v-btn
            color="green"
            class="mx-auto px-4"
            @click="update('AP')"
        >
          Update All InternetUsers for Ansprechpartner
        </v-btn>
        </v-row>
        </v-col>
      </v-card>
    </v-card>
    <v-snackbar
      v-model="loginFail"
      color="error"
      multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{loginFailMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import APIService from "@/services/APIService";
import userService from "@/services/user.service";
export default {
  name: 'UpdateIU',
  data () {
    return {
      email: '',
      password: '',
      showLogin: false,
      loginFail: false,
      loginFailMessage: ''
    }
  },
  methods: {
    ...mapMutations(['updateUser', 'updateManagedUsers']),
    ...mapGetters(['isLoggedIn']),
    async update (type) {
      let usersSynced = 0;
      if(type !== "AP"){
        let allUsers = await APIService.getAllModel("kunde", [{name: "KundeTyp", value: type}]);

        for(let i=0; i<allUsers.length; i++){
          if(allUsers[i].InternetUser && allUsers[i].InternetUser !== "" && allUsers[i].InternetUser !== undefined){
            console.log("User "+i+"/"+allUsers.length);
            console.log("create user "+allUsers[i].Kurzbezeichnung+" IU: "+allUsers[i].InternetUser);
            await userService.setInternetUser(allUsers[i], allUsers[i].id, allUsers[i], allUsers[i])
            usersSynced++;
          }else{
            console.log("no internet user "+allUsers[i].InternetUser);
          }
        }
      }else{
        console.log("create APs");

        let allAPs = await APIService.getAllModel("kundeap");

        for(let i=0; i < allAPs.length; i++) {
          let model = allAPs[i];
          if(model.InternetUser && model.InternetUser !== ""){
            const _user = {...model}
            console.log(_user.Kurzbezeichnung);
            if (_user.P_Kunde && _user.P_Kunde !== "") {
              let kunde = await userService.getModel(_user.P_Kunde);
              _user.Vorname = kunde.Vorname;
              _user.Nachname1 = kunde.Nachname1;
              _user.KundeTyp = kunde.KundeTyp;
            }
            console.log("create or update internetuser");
            console.log(_user);
            //await userService.setInternetUser(_user, _user.id, _user, _user);
          }else{
            console.log("no internet user");
          }
        }
      }
      console.log("num users synced: "+usersSynced);
    }
  }
}
</script>

<style scoped>

</style>
