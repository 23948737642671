import axios from 'axios'
import json from "../../public/db.json";
const serverURL = process.env.VUE_APP_API_SHOP

const config = {
    baseURL: serverURL + '/wp-json/s3m/v1/smp',
    //baseURL: 'https://webquod.de/wq/rest',
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 100000
}

const apiClient = axios.create(config)
apiClient.interceptors.request.use((config) => {
    const token = process.env.VUE_APP_API_TOKEN_SHOP
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})
export default {
    login (username, password) {
        return apiClient.post('/token', {
            username,
            password
        }, {
            baseURL: serverURL + '/wp-json/jwt-auth/v1'
        })
    },
    logout () {
        // Invalidate token
    },
    getUser(id){
        return apiClient.get("/kunde/"+id).then(result => {
            //console.log("User:", result.data.results[0])
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllUser(filter = "", value = "") {
        let addFilter = "?Ort=Stuttgart";
        if(filter != "" && value != ""){
            addFilter += "&"+filter+"="+value;
        }

        return apiClient.get("/kunde"+addFilter).then(result => {
            //console.log("Users:", result.data)
            return result.data.results
        })
    },
    getTelefon(id){
        return apiClient.get("/telefon?UKeyTelefon="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllTelefon() {
        return apiClient.get("/telefon").then(result => {
            //console.log("Users:", result.data)
            return result.data.results
        })
    },
    getGruppe(id){
        return apiClient.get("/gruppe?UKeyTelefon="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllGruppe() {
        return apiClient.get("/gruppe").then(result => {
            //console.log("Gruppen:", result.data.results)
            return result.data.results
        })
    },
    getKundeAp(id){
        return apiClient.get("/kundeap?UKeyKundeAp="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllKundeAp() {
        return apiClient.get("/kundeap").then(result => {
            //console.log("Gruppen:", result.data.results)
            return result.data.results
        })
    },
    getLand(id){
        return apiClient.get("/land?UKeyLand="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllLand(){
        return apiClient.get("/land").then(result => {
            //console.log("Gruppen:", result.data.results)
            return result.data.results
        })
    },
    getKundeApsForFirma(UKeyF_Kunde) {
        return apiClient.get("/kundeap?UKeyF_Kunde="+UKeyF_Kunde).then(result => {
            //console.log("Users:", result.data)
            return result.data
        })
    },
    getTableSchema(table){
        for(let i=0; i<json.fieldmeta.length; i++){
            if(json.fieldmeta[i].table == table){
                return JSON.parse(json.fieldmeta[i].schema);
            }
        }
        let addTableName = "";
        if(table != ""){
            addTableName = "?table="+table;
        }
        return apiClient.get("/fieldmeta/"+addTableName).then(result => {
            //console.log("Schema1:", JSON.parse(result.data[0].schema))
            return JSON.parse(result.data[0].schema)
        })
    },
}
