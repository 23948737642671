import APIService from "@/services/APIService";

export default {
    TelefonTyp: {
        Telefon: "T",
        EMail: "E",
        Mobil: "H",
        Webseite: "U"
    },
    // eslint-disable-next-line no-unused-vars
    async getModel(id = "", schema){
        if(id != ""){
            let model = await APIService.getModel(id);
            model.schema = schema;
            return model;
        }else{
            let model = await APIService.getAllModel();
            for(let i=0; i < model.length; i++){
                model[i].schema = schema;
            }
            return model;
        }
    },
    async getAllTelefonForUserModel(model, isAP = false){
        console.log("get all telefon from user model "+model.KundenNr+" isAp?"+isAP);
        let filterName = "Kunde";
        if(isAP){
            filterName = "KundeAp"
        }
        let filter = [{
            name: filterName,
            value: model.id
        }]
        let userTelefon = await APIService.getAllModel('telefon', filter);
        userTelefon.sort((a, b) => new Date(b.RestTimestampLast) - new Date(a.RestTimestampLast));

        model.telefon = "";
        model.telefonId = "";
        model.mobil = "";
        model.mobilId = "";
        model.email = "";
        model.emailId = "";
        model.webseite = "";
        model.webseiteId = "";

        let telefonTypMap = {};
        let minRFMap = {};

        userTelefon.forEach(item => {
            if(!Object.hasOwnProperty.call(item, 'TelefonTyp')) {
                return;
            }

            let telefonTyp = item.TelefonTyp;
            let hasRF = "RF" in item;
            if (!hasRF) {
                item.RF = "1000";
                hasRF = true;
            }
            let RF = hasRF ? parseInt(item.RF) : undefined;

            // In der telefonTypMap festhalten, ob wir RF = "1" gefunden haben
            if (RF === 1) {
                telefonTypMap[telefonTyp] = true;
            }

            // Wenn das Item kein RF hat oder wenn dieses RF kleiner ist als das kleinste gefundene,
            // dann aktualisieren wir die minRFMap
            if (hasRF && (!(telefonTyp in minRFMap)
                || RF < parseInt(minRFMap[telefonTyp].RF))) {
                minRFMap[telefonTyp] = item;
            }
        });

        // Jetzt durchlaufen wir die minRFMap und setzen RF auf 1 für die entsprechenden Items
        for (let telefonTyp in minRFMap) {
            if (!telefonTypMap[telefonTyp]) {
                let originalItem = userTelefon.find(item => item === minRFMap[telefonTyp]);
                if (originalItem) {
                    originalItem.RF = "1";
                }
                minRFMap[telefonTyp].RF = "1";
            }
        }

        for(let i=0; i<userTelefon.length; i++){
            if(!isAP && (userTelefon[i].KundeAp && userTelefon[i].KundeAp !== '643d445d7304db002719f254')){
                continue;
            }

            if(userTelefon[i].RF.toString() !== "1"){
                continue;
            }

            switch (userTelefon[i].TelefonTyp) {
                case this.TelefonTyp.Telefon: {
                    if (!model.telefon || model.telefon === "") {
                        model.telefon = userTelefon[i].TelefonNr;
                        model.telefonId = userTelefon[i].id;
                    }
                    break;
                }
                case this.TelefonTyp.Mobil: {
                    if (!model.mobil || model.mobil === "") {
                        model.mobil = userTelefon[i].TelefonNr;
                        model.mobilId = userTelefon[i].id;
                    }
                    break;
                }
                case this.TelefonTyp.EMail: {
                    if (!model.email || model.email === "") {
                        model.email = userTelefon[i].TelefonNr;
                        model.emailId = userTelefon[i].id;
                    }
                    break;
                }
                case this.TelefonTyp.Webseite: {
                    if (!model.webseite || model.webseite === "") {
                        model.webseite = userTelefon[i].TelefonNr;
                        model.webseiteId = userTelefon[i].id;
                    }
                    break;
                }
            }
        }
        return model;
    },
    async saveTelefon(model){
        const _model = { ...model }
        // delete _model.KundeAP;

        let res;
        if (_model.id && _model.id !== "") {
            let tm = {};
            tm.TelefonNr = _model.TelefonNr;
            res = await APIService.updateModel("telefon", tm, _model.id);
        } else {

            res = await APIService.createModel("telefon", _model);
        }

        return res;
    },
    async saveAllTelefonFromUserModel(model, isAP = false){
        const telefonModel = {};
        if(isAP){
            //telefonModel.Kunde = "643d445d7304db002719f254";
            telefonModel.KundeAp = model.id;
        }else{
            telefonModel.Kunde = model.id;
            //telefonModel.KundeAp = "643d445d7304db002719f254";
        }

        if(model.telefon !== undefined){
            if (model.telefon && model.telefon.trim() !== "" || model.telefonId && model.telefonId.trim() !== "") {
                console.log("save telefon "+model.telefon);
                telefonModel.TelefonTyp = this.TelefonTyp.Telefon;
                telefonModel.TelefonNr = model.telefon;
                if(model.telefonId && model.telefonId !== "")
                    telefonModel.id = model.telefonId;
                let res = await this.saveTelefon(telefonModel);
                console.log(res);
            }
        }
        delete telefonModel.id;
        if(model.mobil !== undefined){
            if (model.mobil && model.mobil.trim() !== "" || model.mobilId && model.mobilId.trim() !== "") {
                console.log("save mobil " + model.mobil);
                telefonModel.TelefonTyp = this.TelefonTyp.Mobil;
                telefonModel.TelefonNr = model.mobil;
                if (model.mobilId && model.mobilId !== "")
                    telefonModel.id = model.mobilId;
                let res = await this.saveTelefon(telefonModel);
                console.log(res);
            }
        }
        delete telefonModel.id;
        if(model.email !== undefined){
            if (model.email && model.email.trim() !== "" || model.emailId && model.emailId.trim() !== "") {
                telefonModel.TelefonTyp = this.TelefonTyp.EMail;
                telefonModel.TelefonNr = model.email;
                if (model.emailId && model.emailId !== "")
                    telefonModel.id = model.emailId;
                let res = await this.saveTelefon(telefonModel);
                console.log(res);
            }
        }
        delete telefonModel.id;
        if(model.webseite !== undefined){
            if (model.webseite && model.webseite.trim() !== "" || model.webseiteId && model.webseiteId.trim() !== "") {

                console.log("save webseite " + model.webseite);
                telefonModel.TelefonTyp = this.TelefonTyp.Webseite;
                telefonModel.TelefonNr = model.webseite;
                console.log(telefonModel);
                if (model.webseiteId && model.webseiteId !== "")
                    telefonModel.id = model.webseiteId;
                console.log(model.webseiteId);
                console.log(telefonModel);
                let res = await this.saveTelefon(telefonModel);
                console.log(res);
            }
        }
        return model;
    }
}
