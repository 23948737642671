<template>
<div>

  <h2>Information</h2>

  Der Adminbereich ist bis jetzt nur Firmenkunden vorbehalten.

</div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>
