import axios from 'axios'
//import router from '@/router'
//import store from '@/store'
import json from '../../public/db.json';

const config = {
    baseURL: `${process.env.VUE_APP_ROOT_API_2}`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'credentials': 'S3_SHOP:5evOr5DezauNZU7Pewog'
    },
}
//let originalUrl = null

const apiClient = axios.create(config)

/*
function authHeaderInterceptor(config) {
    const token = store.state?.user?.tokens?.access?.token
    if (token) {
        //config.headers.Authorization = `Bearer ${token}`
    }
    return config
}

function accessTokenRefreshInterceptor(error) {
    const status = error.response.status
    const config = error.config
    console.log('originalUrl', originalUrl)
    if(config.url !== '/auth/refresh-tokens') {
        originalUrl = config.url
    }
    if (status !== 401) {
        return Promise.reject(error);
    }
    if (config.url === '/auth/login'
        || config.url === '/sso/authTokens') {
        return Promise.reject(error);
    }

    // TODO check if user is disabled
    if (config.url === '/auth/refresh-tokens') {
        store.commit('logout')
        if(originalUrl !== '/sso/authCode') {
            router.push({ name: 'adminLogin'});
        }
        return Promise.reject(error);
    }
    const refreshToken = store.getters.refreshToken ? store.getters.refreshToken : 'invalid_token'
    if(!config._retry) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            const tokens = response?.data
            store.commit('updateTokens', tokens)
            config.headers['Authorization'] = `Bearer ${tokens?.access?.token}`;
            config._retry = true
            return apiClient.request(config)
        })
    }
}

apiClient.interceptors.request.use((config) => {
    return authHeaderInterceptor(config)
})
apiClient.interceptors.response.use(response => {
    return response
}, error => {
    return accessTokenRefreshInterceptor(error)
})
+7
 */
export default {
    login(email, password) {
        return apiClient.post('/auth/login', { email, password }).then(response => {
                console.log(response)
                return response.data
            }
        )
    },
    logout(refreshToken) {
        return apiClient.post('/auth/logout', { refreshToken }).catch((error) => {
            if (error.response && error.response.status !== 404) {
                throw error;
            }
        })
    },
    refreshToken(refreshToken) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            return response?.data
        })
    },
    getUser(id){
        return apiClient.get("/kunde/"+id).then(result => {
            //console.log("User:", result.data.results[0])
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllUser(filter = "", value = "") {
        let addFilter = "";
        if(filter != "" && value != ""){
            addFilter += "?"+filter+"="+value;
        }
        return apiClient.get("/kunde"+addFilter).then(result => {
            //console.log("Users:", result.data)
            return result.data.results
        })
    },
    getTelefon(id){
        return apiClient.get("/telefon?UKeyTelefon="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllTelefon() {
        return apiClient.get("/telefon").then(result => {
            //console.log("Users:", result.data)
            return result.data.results
        })
    },
    getGruppe(id){
        return apiClient.get("/gruppe?UKeyTelefon="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllGruppe() {
        console.log("get all gruppe");
        return apiClient.get("/gruppe").then(result => {
            //console.log("Gruppen:", result.data.results)
            return result.data.results
        })
    },
    getKundeAp(id){
        return apiClient.get("/kundeap?UKeyKundeAp="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllKundeAp() {
        return apiClient.get("/kundeap").then(result => {
            //console.log("Gruppen:", result.data.results)
            return result.data.results
        })
    },
    getLand(id){
        return apiClient.get("/land?UKeyLand="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllLand(){
        return apiClient.get("/land").then(result => {
            //console.log("Gruppen:", result.data.results)
            return result.data.results
        })
    },
    getKundeApsForFirma(UKeyF_Kunde) {
        return apiClient.get("/kundeap?UKeyF_Kunde="+UKeyF_Kunde).then(result => {
            //console.log("Users:", result.data)
            return result.data
        })
    },
    getTableSchema(table){
        for(let i=0; i<json.fieldmeta.length; i++){
            if(json.fieldmeta[i].table == table){
                return JSON.parse(json.fieldmeta[i].schema);
            }
        }
        let addTableName = "";
        if(table != ""){
            addTableName = "?table="+table;
        }
        console.log("call api client");
        return apiClient.get("/fieldmeta/"+addTableName).then(result => {
            //console.log("Schema1:", JSON.parse(result.data[0].schema))
            return JSON.parse(result.data[0].schema)
        })
    },
}
