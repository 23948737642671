<template>
  <div class="s3m-gruppen_user_dialog">
    <v-row justify="center">
    <OverlayDialog
        v-model="showDialog"
        persistent
        max-width="800px"
        style="z-index:100"
        v-on:close="this.close"
        :show-save-button="false"
        content-class="s3m-gruppen_user_select_dialog"
        class="s3m-gruppen_user_select_dialog"
    >
    <h1>{{title}}</h1>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="list.length < 5"
          @click:row="rowClick"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
      >
        <!--
        <template v-slot:item.actions="{ item }">

          <v-icon
              v-if="!item.userHasGroup"
              small
              @click="addToGroup(item)"
          >
            mdi-plus
          </v-icon>
          <v-icon
              v-if="item.userHasGroup"
              small
              @click="removeFromGroup(item)"
          >
            mdi-minus
          </v-icon>
        </template>
        -->
      </v-data-table>
    </v-card>
    <confirm-dialog ref="confirmDelete"/>
      <generated-form ref="generatedForm"/>
      <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
    </OverlayDialog>
  </v-row>
  </div>
</template>

<script>
//import APIService from "@/services/APIService";
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import OverlayDialog from "@/components/OverlayDialog.vue";
import sammelgruppeService from "@/services/sammelgruppe.service";
import GruppemitgliedService from "@/services/gruppemitglied.service";
import GeneratedForm from "@/components/GeneratedForm.vue";
export default {
  name: "GruppenUserSelectDialog",
  mixins: [vuetifyTableMixin],
  components: {
    OverlayDialog,
    ConfirmDialog,
    GeneratedForm
  },
  data() {
    return {
      showDialog: false,
      showLoader: false,
      type: "",
      item: {},
      search: '',
      title: 'Gruppen in Sammelgruppe',
      list: [],
      userGruppen: [],
      sortBy: 'userHasGroupTxt',
      sortDesc: true,
      headers: [
        { text: 'Name', value: 'KurzbezeichnungExtern' },
        { text: 'KundeTyp', value: 'KundeTyp'},
        { text: 'InternetUser', value: 'InternetUser'},
        /*{
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        },*/
      ]
    }
  },
  methods: {
    rowClick(item, row){
      console.log("clicked "+row);
      console.log(item);
      if(item.KundeTyp == 'AP'){
        console.log(item.ap);
        this.$refs.generatedForm.show("kundeap", item.ap, item.ap.id, "", false)
      }else{
        this.$refs.generatedForm.show("kunde", item, item.id, item.KundeTyp)
      }

    },
    show(item){
      console.log(item);
      this.model = item;
      this.showDialog = true;
      this.fetchModel();
      this.title = "Mitglieder in Gruppe "+item.Bezeichnung;
    },
    close(){
      this.showDialog = false;
    },
    async addToGroup(){
    },
    async removeFromGroup(item){
      console.log(item);
      this.showLoader = true;
      await sammelgruppeService.removeGroup(this.model, item);
      await this.fetchModel();
      this.showLoader = false;
      this.$root.$emit('updatesammelgruppeList', "sammelgruppe");
    },
    async fetchModel(){
      this.showLoader = true;
      this.list = await GruppemitgliedService.getMitgliederForGruppe(this.model.id);
      console.log(this.list);
      this.showLoader = false;
    }
  },
  /*
  computed: {
    ...mapState(['users'])
  },*/
  watch:{
    $route (){
      //this.entered();
    }
  },
  created() {
    //this.entered();
  }
}
</script>

<style scoped>

</style>
