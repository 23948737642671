<template>
  <div class="s3m-gruppen_list_field">
    <div>
      <ul
          class="form-control"
      >
        <li v-for="item in list" :key="item.Kurzbezeichnung" v-on:click="showItem(item)">{{ item.Kurzbezeichnung }}</li>
      </ul>
    </div>
    <generated-form ref="generatedForm"/>
  </div>
</template>

<script>
import { abstractField } from "vue-form-generator";
import GeneratedForm from "@/components/GeneratedForm.vue";

export default {
  name: "KundenListField",
  components: {
    GeneratedForm,

  },
  data: () => ({
    list: []
  }),
  mixins: [ abstractField ],
  methods: {
    create(){
      console.log("created firmen");

    },
    edit () {

    },
    showItem(item){
      console.log(item);
      this.$refs.generatedForm.show("kunde", item, item.id, item.KundeTyp)
    }
  },
   mounted() {
       if(!this.value){
         return;
       }
       this.list = this.value;
       console.log(this.list);
       this.$root.$off("updateList");
       this.$root.$on('updateList', (msg) => {
         console.log("update list "+msg);
         if(msg == 'kundenList'){
           console.log("update firmen in user detail dialog");
         }
       });
    }
};
</script>
