<template>
  <div class="user_show">
    <v-row justify="center">
      <v-dialog
          v-model="showDialog"
          persistent
          max-width="380px"
          content-class="user_edit_dialog"
      >
        <v-card>
          <v-card-title>
            <span class="headline"></span>
          </v-card-title>
          <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <h3 class="kurzbezeichnung">{{user.KurzbezeichnungExtern}}</h3>
                    <br>
                    <div class="kundennr">KundenNr: <span>{{user.KundenNr}}</span></div>
                    <div v-if="user.Innung" class="innung">Innung: <span>{{user.Innung.Name}}</span></div>
                    <div class="login">E-Mail Adresse für Login: <span>{{user.InternetUser}}</span></div>
                    <br>
                    <h3>Adresse</h3>
                    <div v-html="adresse" class="adresse"></div>
                    <br>
                    <h3>Kontaktdaten</h3>
                    <div class="email">E-Mail: <span>{{user.email}}</span></div>
                    <div class="telefon">Telefon: <span>{{user.telefon}}</span></div>
                    <div class="mobil">Mobil: <span>{{user.mobil}}</span></div>
                    <div class="website">Webseite: <span>{{user.webseite}}</span></div>

                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="close"
            >
              Abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>

import telefonService from "@/services/telefon.service";
import userService from "@/services/user.service";

export default {
  name: 'ShowUserDialog',
  components: {  },
  data: () => ({
    showDialog: false,
    adresse: "",
    user: {}
  }),
  methods: {
    async show (user) {
      this.user = await telefonService.getAllTelefonForUserModel(user);
      if(this.user.KundeTyp === "F" && !this.user?.Innung?.Name){
        this.user = await userService.getInnungForModel(user);
      }

      this.adresse = "";
      if(this.user.KundeTyp === "P"){
        this.adresse = this.user.Vorname+" "+this.user.Nachname1+"<br>";
      }else{
        this.adresse = this.user.Adresse11+"<br>"+(this.user.Adresse12 ?? "")+"<br>"+(this.user.Adresse13 ??  "")+"<br>"+(this.user.Adresse14 ??  "");
      }
      this.adresse = this.adresse+this.user.Strasse+"<br>"+this.user.PLZ_Str+" "+this.user.Ort;

      this.showDialog = true

    },
    close () {
      this.showDialog = false
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>

</style>
