var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.listClass},[_c('h1',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"s3m_header_title",staticStyle:{"flex-grow":"1"}},[_vm._v(_vm._s(_vm.title))]),(_vm.selectionMode)?_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","color":"white"},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche (min. 3 Zeichen)","single-line":"","hide-details":"","value":_vm.search},on:{"input":_vm.searchHandler}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[(!_vm.selectionMode)?_c('v-btn',{attrs:{"rounded":"","color":"primary","dark":""},on:{"click":_vm.createUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(this.newUserString)+" ")],1):_vm._e()],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"search":_vm.search,"items-per-page":50,"hide-default-footer":_vm.users.length < 5,"options":_vm.paginationSync,"footer-props":{
          'items-per-page-text': 'Datensätze',
          'items-per-page-options': [15, 50, 100, -1]
        }},on:{"click:row":_vm.selectedUser,"update:options":[_vm.updateOptions,function($event){_vm.paginationSync=$event}]},scopedSlots:_vm._u([{key:"item.KundenNr",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"s3m_clickable",on:{"click":function($event){$event.stopPropagation();return _vm.showUserData(item)}}},[_vm._v(_vm._s(item.KundenNr))])]}},{key:"item.KurzbezeichnungExtern",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"s3m_clickable",on:{"click":function($event){$event.stopPropagation();return _vm.showUserData(item)}}},[_vm._v(_vm._s(item.KurzbezeichnungExtern))])]}},{key:"item.InternetUser",fn:function(ref){
        var item = ref.item;
return [_c('div',{class:{ 's3m_clickable': !_vm.selectionMode },on:{"click":function($event){_vm.selectionMode ? null : _vm.openLoginUser(item)}}},[_vm._v(_vm._s(item.InternetUser))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.kundetyp === 'P' && !_vm.selectionMode)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.copyForAP(item)}}},[_vm._v(" mdi-home-plus ")]):_vm._e(),(_vm.selectionMode)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.selectedUser(item)}}},[_vm._v(" mdi-plus ")]):_vm._e(),(!_vm.selectionMode)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!_vm.selectionMode)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.role",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"ma-0",staticStyle:{"color":"white","width":"80px"},attrs:{"color":item.role === 'admin' ?  'red' : item.role==='user' ? 'green' : 'blue',"small":""}},[_vm._v(" "+_vm._s(item.role)+" ")])]}}])})],1),_c('user-edit-dialog',{ref:"userEditDialog"}),_c('show-user-dialog',{ref:"showUserDialog"}),_c('generated-form',{ref:"generatedForm"}),_c('confirm-dialog',{ref:"confirmDelete"}),(_vm.showLoader)?_c('Spinner',{staticClass:"s3m-spinner",attrs:{"name":"circle","color":"#008bc4"}}):_vm._e(),_c('snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }