<template>
  <div :class="listClass">
    <h1 style="display: flex; align-items: center;">
      <span class="s3m_header_title" style="flex-grow: 1;">{{ title }}</span>
      <v-btn
          v-if="selectionMode"
          icon
          class="ma-2"
          @click="closeDialog"
          color="white"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </h1>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                append-icon="mdi-magnify"
                label="Suche (min. 3 Zeichen)"
                single-line
                hide-details
                :value="search"
                @input="searchHandler"
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                v-if="!selectionMode"
                rounded
                color="primary"
                dark
                @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ this.newUserString }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          @click:row="selectedUser"
          :items="filteredUsers"
          :search="search"
          :items-per-page="50"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="users.length < 5"
          :options.sync="paginationSync"
          :footer-props="{
            'items-per-page-text': 'Datensätze',
            'items-per-page-options': [15, 50, 100, -1]
          }"
      >
        <template v-slot:item.KundenNr="{item}">
          <div class="s3m_clickable" @click.stop="showUserData(item)">{{ item.KundenNr }}</div>
        </template>
        <template v-slot:item.KurzbezeichnungExtern="{item}">
          <div class="s3m_clickable"  @click.stop="showUserData(item)">{{ item.KurzbezeichnungExtern }}</div>
        </template>
        <template v-slot:item.InternetUser="{item}">
          <div :class="{ 's3m_clickable': !selectionMode }" @click="selectionMode ? null : openLoginUser(item)">{{ item.InternetUser }}</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              v-if="kundetyp === 'P' && !selectionMode"
              small
              class="mr-2"
              @click="copyForAP(item)"
          >
            mdi-home-plus
          </v-icon>
          <v-icon
              v-if="selectionMode"
              small
              class="mr-2"
              @click="selectedUser(item)"
          >
            mdi-plus
          </v-icon>
          <v-icon
              v-if="!selectionMode"
              small
              class="mr-2"
              @click="editUser(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              v-if="!selectionMode"
              small
              @click="deleteUser(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.role="{ item }" class="ml-0">
          <v-chip
              :color="item.role === 'admin' ?  'red' : item.role==='user' ? 'green' : 'blue'"
              class="ma-0"
              small
              style="color: white; width: 80px"
          >
            {{item.role}}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <user-edit-dialog ref="userEditDialog"/>
    <show-user-dialog ref="showUserDialog"/>
    <generated-form ref="generatedForm"/>
    <confirm-dialog ref="confirmDelete"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
    <snackbar ref="snackbar"></snackbar>
  </div>

</template>

<script>
//import store from '@/store/index'
import UserEditDialog from '@/components/UserEditDialog'
import GeneratedForm from "@/components/GeneratedForm";
import APIService from "@/services/APIService";
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import modelService from "@/services/model.service";
import Fuse from 'fuse.js'
import ShowUserDialog from "@/components/ShowUserDialog.vue";
import showUserDialog from "@/components/ShowUserDialog.vue";
import userService from "@/services/user.service";
import Snackbar from "@/components/Snackbar.vue";

//import {mapState} from "vuex";
export default {
  name: "UserList",
  mixins: [vuetifyTableMixin],
  components: {
    Snackbar,
    ShowUserDialog,
    GeneratedForm,
    UserEditDialog,
    ConfirmDialog,
  },
  props: {
    listType: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      search: '',
      listClass: 's3m-user_list',
      showLoader: false,
      paginationSync: {rowsPerPage: 100},
      filters: [],
      title: 'Personen',
      newUserString: 'Benutzer',
      kundetyp: 'P',
      users: [],
      filteredUsers: [],
      timeout: null,
      selectionMode: false,
      snackbarSuccess: {
        text: 'Daten erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      snackbarError: {
        text: 'Daten erfolgreich gespeichert',
        icon: 'mdi-close',
        color: 'error'
      },
    }
  },
  methods: {
    searchHandler (text) {
      if (this.timeout)
        clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        // Stellen Sie sicher, dass die keys den Namen Ihrer Felder in den Benutzerdaten entsprechen
        let options = {
          shouldSort: true,
          threshold: 0.3,
          location: 0,
          includeScore: true,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 2,
          useExtendedSearch: true, // Enable extended search
          tokenize: true,
          matchAllTokens: true,
          keys: ["suchstring"]
          //keys: ["Vorname", "Nachname1", "Nachname2", "PLZ_Str", "Strasse", "InternetUser", "Ort"]
          //keys: ["Vorname", "Nachname1", "Ort"]
        };

        text = text.trimEnd();

        if(text.length > 2) {
          let fuse = new Fuse(this.users, options);
          let extendedSearchQuery = text.split(' ').map(word => `'${word}`).join(' '); // Format the search query as extended search
          let results = fuse.search(extendedSearchQuery);
          this.filteredUsers = results.map(result => result.item);
        }else if(text.length == 0){
          this.filteredUsers = this.users;
        }
      }, 500); // delay
    },
    createUser () {
      this.$refs.generatedForm.show("kunde", null, "", this.kundetyp)
    },
    editUser (item) {
      this.$refs.generatedForm.show("kunde", item, item.id, this.kundetyp)
      //this.$refs.userEditDialog.show(item)
    },
    showUserData(item){
      this.$refs.showUserDialog.show(item);
    },
    async openLoginUser(item) {
      let user = await APIService.getUserByEmail(item.InternetUser);

      if (user === undefined) {
        this.$refs.confirmDelete.show({
          title: 'Login-User erstellen',
          text: `Der Login-User mit der E-Mail Adresse ${item.InternetUser} ist nicht vorhanden. Soll er erstellt werden?`,
          confirm: 'Ja',
        }).then(async () => {
          userService.createLoginUser(item, item.id)
              .then(async () => {
                user = await APIService.getUserByEmail(item.InternetUser);
                this.$refs.userEditDialog.show(user)
              })
              .catch(error => console.log(error));
        });
      } else {
        this.$refs.userEditDialog.show(user);
      }
    },
    copyForAP (item) {
      this.$router.push({
        //path: '/admin/companies?filter=KundeTyp&value=F&addAP=1',
        name: "companies",
        query: {
          filter: "KundeTyp",
          value: "F",
        },
        params: {
          user: {
            ...item
          }
        }
      });
    },
    setSelectionMode(){
      this.selectionMode = true;
      this.title = "Ansprechpartner wählen";
    },
    selectedUser(item){
      if(this.selectionMode){
        this.$emit('userSelected', item);
      }
    },
    closeDialog(){
      this.$emit('closeDialog');
    },
    async entered(){
      this.search = "";

      if(this.$refs.generatedForm){
        this.$refs.generatedForm.close();
      }

      if(this.$route.name === "companies" && this.listType !== "selectAP"){
        this.title = "Firmen";
        this.listClass = "s3m-company_list";
        this.kundetyp = "F";
        this.newUserString = "Firma";
        if(this.$route.params.user){
          this.$store.state.copyUser = this.$route.params.user;
        }else{
          this.$store.state.copyUser = null;
        }
      }else{
        this.title = "Personen";
        this.kundetyp = "P";
        this.newUserString = "Person";
      }
      if(this.listType === "selectAP"){
        this.setSelectionMode();
      }
      if(this.$route.query.filter && this.$route.query.value){
        await this.fetchUsers(this.$route.query.filter, this.$route.query.value);
      }
    },
    async fetchUsers(filter = "", value = ""){
      this.showLoader = true;
      if(filter !== "" && value !== ""){
        this.filters = [];
        this.filters.push({"name": filter, "value": value})
        //this.filters.push({name: "Archiv", value: "0"});
      }

      //this.filters.push({'name': 'limit', 'value': '100'});
      this.users = await APIService.getAllModel("kunde", this.filters)
      for(let i=0; i < this.users.length; i++){
        if(this.users[i].Archiv === "-1"){
          this.users.splice(i, 1);
          continue;
        }
        if(!this.users[i].KurzbezeichnungExtern || this.users[i].KurzbezeichnungExtern === ""){
          if(this.users[i].KundeTyp === "F"){
            this.users[i].KurzbezeichnungExtern = (this.users[i].Adresse11 ?? "")  + " " + (this.users[i].Adresse12 ?? "") ;
          }else{
            this.users[i].KurzbezeichnungExtern = (this.users[i].AkademischerGrad ?? "") + " ";
          }
          this.users[i].KurzbezeichnungExtern += (this.users[i].Vorname ?? "") + " " + (this.users[i].Nachname1 ?? "") + " " + (this.users[i].Nachname2 ?? "") + " " + (this.users[i].Nachname3 ?? "") ;
        }
        this.users[i].completeName = this.users[i].Vorname + " " + this.users[i].Nachname1;
        this.users[i].suchstring =  (this.users[i].KurzbezeichnungExtern ?? "") + " " + this.users[i].Strasse + " " + this.users[i].PLZ_Str + " " + this.users[i].Ort + " " + this.users[i].KundenNr + " " + this.users[i].InternetUser;
      }

      this.filteredUsers = this.users;
      this.showLoader = false;
    },
    async deleteUser (user) {
      this.$refs.confirmDelete.show({
        title: 'Benutzer entfernen',
        text: `Wollen sie <b>${user.firstName} ${user.lastName}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(async () => {
        let ret = await modelService.deleteModel(user, "kunde");
        if(ret && ret.status && (ret.status === 200 || ret.status === 204)){
          if(user.InternetUser !== ""){
            await userService.deleteLoginUserByMail(user.InternetUser);
          }
          //show success
          this.snackbarSuccess.text = "Der Kunde wurde erfolgreich gelöscht.";
          this.$refs.snackbar.show(this.snackbarSuccess)
          this.fetchUsers();
        }else{
          //show error
          this.snackbarError.text = "Der Kunde konnte nicht gelöscht werden. Es gibt möglicherweise Fremdbeziehungen, die das Löschen verhindern.";
          this.$refs.snackbar.show(this.snackbarError)
        }
      })
    },
  },
  computed: {
    showUserDialog() {
      return showUserDialog
    },
    headers(){
      const headers = [
          /*
        { text: 'Vorname', value: 'Vorname' },
        { text: 'Name', value: 'Nachname1' },*/
          {text: "KundenNr", value: "KundenNr"},
        { text: 'Kurzbezeichnung', value: 'KurzbezeichnungExtern' },
        /*{ text: "Typ", value: 'KundeTyp'},*/
        { text: 'PLZ', value: 'PLZ_Str' },
        { text: 'Ort', value: 'Ort' },
        { text: 'Straße', value: 'Strasse' },
        { text: 'InternetUser', value: 'InternetUser' },
        {
          value: 'completeName',
          align: ' d-none'
        },
        {
          value: 'Vorname',
          align: ' d-none'
        },
        {
          value: 'Nachname1',
          align: ' d-none'
        },

      ]
      if (!this.selectionMode) {
        headers.push(        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        });
      }
      if(this.kundetyp == "P"){
        //headers.splice(4, 1);
      }else{
        //headers.splice(0,1);
      }
      return headers;
    }
  },
  mounted() {
    this.$root.$off("updatekundeList");
    this.$root.$on('updatekundeList', (msg) => {
      if(msg === "kunde"){
        this.fetchUsers();
      }
    });
  },
  watch:{
    $route (){
      this.entered();
    }
  },
  created() {
    this.entered();
  }
}
</script>

<style scoped>

</style>
