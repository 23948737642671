<template>
  <div class="center">

    <generated-form ref="generatedForm"></generated-form>
  </div>
</template>

<script>
import GeneratedForm from "@/components/GeneratedForm";
//import APIService from "@/services/APIService";
import {mapMutations} from "vuex";
export default {
  name: "UserRegistration",
  components: {
    'generated-form': GeneratedForm
  },
  methods:{
    ...mapMutations(['updateUser']),
    async createUser () {

      this.$refs.generatedForm.show("kunde", null, "", "RF", true, true)

      },
  },
  mounted() { // oder `created()`, je nachdem was bei Ihrem Anwendungsfall besser geeignet ist
    this.createUser();
  },
}
</script>

<style scoped>

</style>
