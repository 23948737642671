import APIService from "@/services/APIService";
import APIServiceTMPSMP from "@/services/APIServiceTMPSMP";
import gruppemitgliedService from "@/services/gruppemitglied.service";
import telefonService from "@/services/telefon.service";
import APIServiceWP from "@/services/APIServiceWP";
import sammelgruppeService from "@/services/sammelgruppe.service";
import kundeapService from "@/services/kundeap.service";
import APIServiceSync from "@/services/APIServiceSync";
import utils from "@/utils/utils"

export default {
    async getModel(id = "", schema = [], testmode = false){
        if(id !== ""){
            let model = {}
            if(testmode) {
                model = await APIServiceTMPSMP.getUser(id);
            }else{
                model = await APIService.getModel("kunde", id);
            }
            model.schema = schema;
            //model = await this.getContext(model, testmode);
            return model;
        }else{
            let model = {}
            if(testmode) {
                model = await APIServiceTMPSMP.getUsers();
            }else{
                console.log("getAllModel kunde");
                model = await APIService.getAllModel("kunde", []);
                for(let i=0; i<model.length; i++){
                    if(model.Archiv === "-1"){
                        model.splice(i, 1);
                    }
                }
            }
            /*
            for(let i=0; i<model.length(); i++){
                model[i] = await this.getContext(model[i], testmode);
            }*/
            return model;
        }
    },

    async saveModel(model, oldModel){

        console.log("save model");
        console.log(model);
        // eslint-disable-next-line no-unreachable
        const _model = { ...model }

        //Rest
        delete _model.RestTimestampCreate;
        delete _model.RestTimestampLast;

        //Firma
        delete _model.importedAt;
        delete _model.Geodat_Laenge;
        delete _model.Geodat_Breite;
        delete _model.Geodat_Checked;
        delete _model.Innung;
        delete _model.allLand;
        delete _model.schema;
        delete _model.gruppen;
        delete _model.aps;
        delete _model.InternetExportJaNein;
        delete _model.InternetUserMaster;
        delete _model.land;
        delete _model.created;
        delete _model.firmen;
        delete _model.Archiv;
        delete _model.KurzbezeichnungManuell;
        delete _model.logoName;

        //Person
        delete _model.Geburtstag;
        delete _model.password;
        delete _model.password2;

        let accessType = _model.accessType ?? "";
        delete _model.accessType;
        delete _model.schemaType;
        delete _model.berufsgruppe;

        let isRegistration = _model.isRegistration ?? false;
        delete _model.isRegistration;
        let registrationType = _model.registrationType ?? "";
        delete _model.registrationType;

        //get differences from _model to oldModel
        let differences = [];
        if(accessType === "R"){
            //send mail to Admin with changed values
            for (const key in _model) {
                if (Object.prototype.hasOwnProperty.call(_model, key) && Object.prototype.hasOwnProperty.call(oldModel, key)) {
                    if (_model[key] !== oldModel[key]) {
                        differences.push({parameter: key, old: oldModel[key], new: _model[key]});
                    }
                }
            }
        }

        //contact
        if(!isRegistration) {
            delete _model.telefon;
            delete _model.mobil;
            delete _model.email;
            delete _model.webseite;
        }
        delete _model.telefonId;
        delete _model.mobilId;
        delete _model.emailId;
        delete _model.webseiteId;

        if ((model.InternetUser && model.InternetUser.trim() !== "") && !utils.validateEmail(model.InternetUser.trim())) {
            return {status: false, error: "invalid_email_internetuser"};
        }
        if ((model.email && model.email.trim() !== "") && !utils.validateEmail(model.email.trim())) {
            return {status: false, error: "invalid_email_contact"};
        }

        //prepare for save
        if(model.land && model.land.id){
            _model.Land = model.land.id;
        }
        if(!model.KundeTyp || model.KundeTyp === ""){
            _model.KundeTyp = "P";
        }
        if(model.Geburtstag && model.Geburtstag !== "" && model.Geburtstag !== " "){
            const geb = new Date(model.Geburtstag);
            _model.Geburtstag = geb.getFullYear() + '-' + String(geb.getMonth()+1).padStart(2, '0') + '-' + String(geb.getDate()).padStart(2, '0');
        }
        if(model.KundeTyp === 'F'){

            console.log("firma");
            if (model.schemaType === "kunde_RF") {
                console.log("kunde_rf");
                if (!_model.Adresse11 || _model.Adresse11.trim() === "") {
                    return {status: false, error: "address_missing"};
                }

                if (!_model.InternetUser || _model.InternetUser.trim() === "" || !utils.validateEmail(_model.InternetUser.trim())) {
                    return {status: false, error: "invalid_email_internetuser"};
                }
            }

            if(!model.KurzbezeichnungExtern){
                _model.KurzbezeichnungExtern =
                    ((model.Adresse11 || '') + " " + (model.Adresse12 || ''));
            }
            if(!model.Adresse11){
                _model.Adresse11 = "-";
            }
            _model.Nachname1 = "";
            if(!model.id && !_model.Organisiert) _model.Organisiert = "Nein";
            delete _model.Organisiert;
        }else{
            if ((!model.Vorname || model.Vorname.trim() === "") && (!model.Nachname1 || model.Nachname1.trim() === ""))
                return;

            if(!model.KurzbezeichnungExtern){
                _model.KurzbezeichnungExtern =
                    ((model.Vorname || '') + " " + (model.Nachname1 || ''));
            }
        }

        //temp
        // eslint-disable-next-line no-unreachable
        delete _model.UKeyLand;
        delete _model.Umsatzsteuer_ID;

        //all null to empty string
        Object.keys(_model).forEach(function(key) {
            if (this[key] == null) this[key] = " ";
            if (this[key] == ''){
                this[key] = " ";
            }
            if(this[key] === " ") delete this[key];
        }, _model);

        /**
         * User PHP Sync for Registration
         *
         */
        if(isRegistration){
            _model.password =  model.password;
            _model.registrationType = registrationType;
            _model.berufsgruppe = model.berufsgruppe;
            console.log(oldModel);
            console.log(_model);

            let response = await APIServiceSync.syncUserRegistration(_model);
            console.log(response);
            return response
        }

        //check if internetuser already exists
        // eslint-disable-next-line no-unreachable
        if(_model.InternetUser === undefined) _model.InternetUser = "";
        if(_model.InternetUser !== oldModel.InternetUser) {
            if(!_model.InternetUser || !_model.InternetUser.trim()){
                await this.deleteLoginUserByMail(oldModel.InternetUser);
                _model.InternetUser = "";
            }else if(!utils.validateEmail(_model.InternetUser)){
                return {status: false, error: "invalid_email_internetuser"};
            }
            if (_model.InternetUser !== "" && _model.InternetUser !== " ") {
                let res = await APIService.getKundeOrAPWithInternetUser(_model.InternetUser);
                if (res) {
                    return {status: false, error: "email_taken"};
                }
            }
        }else{
            //delete _model.InternetUser;
        }
        let res;
        if(!model.id){
            res = await APIService.createModel("kunde", _model);
            if(!res){
                return res;
            }
            model.id = res.id;
            _model.id = res.id;
        }else {
            delete _model.id;
            delete _model.UKeyKunde;
            res = await APIService.updateModel("kunde", _model, model.id);
            if(!res){
                return res;
            }
        }
        //create SSO User if InternetUser is set
        model.id = res.id;

        await this.saveContact(model, oldModel);

        if(accessType !== "R" && accessType !== "RT"){
            await this.setInternetUser(_model, model.id, oldModel, model);
        }

        //add user to registraion group and send mails if isRegistraion
        if(isRegistration){
            //65f0af872000c2002840a9a6
            if(registrationType === "ms"){
                let gmModel = gruppemitgliedService.createSimpleModel("661fb386260c1100265b02d3", model.id, model.KundeTyp);
                await gruppemitgliedService.saveModel(gmModel);
                await APIService.sendAdminMail("Registrierung eines neuen Users - Mission Stuckateur", model.InternetUser+' hat sich neu für Mission Stuckateur registriert. Er wurde in die Gruppe "Neuregistrierung Mission Stuckateur" (REGMS) eingetragen.');

            }else{
                let gmModel = gruppemitgliedService.createSimpleModel("65f0af872000c2002840a9a6", model.id, model.KundeTyp);
                await gruppemitgliedService.saveModel(gmModel);
                await APIService.sendAdminMail("Registrierung eines neuen Users", model.InternetUser+' hat sich neu registriert. Er wurde in die Gruppe "Neuregistrierung Self-Service" (REGNEU) eingetragen.');
            }
        }
        //send mail to admin with differences if selfservice
        if(accessType === "R" && differences.length > 0){
            let differenceList = differences.map(diff => `${diff.parameter}: von ${diff.old} zu ${diff.new}`);
            let emailText = `KundenNr: ${_model.KundenNr}\n\nÄnderungen:\n${differenceList.join('\n')}`;
            await APIService.sendAdminMail("Änderung der Userdaten von "+model.InternetUser, emailText);
        }

        if(res.status === 200){
            console.log("success on save");
            //await this.syncModel(model, id);
        }
        return res;
    },
    async setInternetUser(_model, id, oldModel, origModel){
        console.log("setInternetUser");
        if(_model.InternetUser != null && _model.InternetUser !== "" && _model.InternetUser !== " "){
            let user = await APIService.getUserByEmail(oldModel.InternetUser);
            if(user == null){
                user = await  APIService.getUserByEmail(_model.InternetUser);
            }
            //console.log(user);
            if(user){
                if(user.kunde && user.kunde !== ""){
                    if(_model.InternetUser !== oldModel.InternetUser){
                        //update email Address
                        user.email = _model.InternetUser;
                        await APIService.updateUser(user);
                    }
                }else{
                    console.log(user);
                    user.kunde = id;
                    user.email = _model.InternetUser;
                    await APIService.updateUser(user);
                }
            }else{
                //create new sso user
                await this.createLoginUser(_model, id, origModel.password ?? "");
            }
            //sync user with other sites
            console.log("sync model");
            console.log(origModel);
            let ret = await this.syncModel(origModel, oldModel);
            console.log(ret);
        }
    },
    async createLoginUser(_model, id, password = ""){
        let ssouser = {};
        ssouser.email = _model.InternetUser;
        ssouser.kunde = id;
        console.log(password);
        ssouser.password = (password !== "") ? password : this.generatePassword();
        ssouser.role = "user";
        ssouser.firstName = _model.Vorname ?? " ";
        ssouser.lastName = _model.Nachname1 ?? " ";
        try {
            await APIService.saveUser(ssouser);
        }catch (ex){
            console.log(ex);
        }
    },
    async deleteLoginUserByMail(email){
        let user = await APIService.getUserByEmail(email);
        if(user != null){
            user = await  APIService.deleteUser(user);
        }
    },
    generatePassword() {
        let password = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let numbers = '0123456789';
        let letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let charactersLength = characters.length;
        let hasNumber = false;
        let hasLetter = false;

        while (!hasNumber || !hasLetter) {
            password = '';
            for (let i = 0; i < 8; i++ ) {
                let char = characters.charAt(Math.floor(Math.random() * charactersLength));
                password += char;
                if (numbers.indexOf(char) > -1) hasNumber = true;
                if (letters.indexOf(char) > -1) hasLetter = true;
            }
        }

        return password;
    },
    async syncModel(model, oldModel){
        const _model = { ...model }
        console.log("sync model 1 "+model.InternetUser);
        console.log(model);
        _model.user_login = model.InternetUser;
        _model.first_name = model.Vorname ? model.Vorname : "";
        _model.last_name = model.Nachname1 ? model.Nachname1 : "";
        _model.user_email = model.InternetUser;
        _model.old_email = oldModel.InternetUser;
        _model.id = model.id;

        if(model.InternetUser !== ""){

            delete _model.schema;

            console.log(_model);

            let sites = JSON.parse(process.env.VUE_APP_SYNC_SITES);
            for(let i=0; i < sites.length; i++) {

                console.log("sync " + sites[i]);
                if(sites[i] === "shop"){
                    if(_model.KundeTyp !== "F"){
                        console.log("no company dont sync with shop!");
                        continue;
                    }
                }
                APIServiceWP.setAPIClient(sites[i]);
                await APIServiceWP.postUser(_model);
            }
            return true;
        }
    },

    async setUserRole(model){
        const _model = { ...model }
        console.log("sync model role "+model.email);
        console.log(model);
        _model.role = model.role;
        _model.user_email = model.email;
        _model.id = model.id;
        _model.UKeyKunde = model.kundeObj.UKeyKunde;
        if(model.email != ""){

            let sites = JSON.parse(process.env.VUE_APP_SYNC_SITES);
            for(let i=0; i < sites.length; i++) {
                console.log("sync " + sites[i]);
                APIServiceWP.setAPIClient(sites[i]);
                await APIServiceWP.setUserRole(_model);
            }
            return true;
        }
    },
    async setUserPassword(model){
        const _model = { ...model }
        console.log("sync model pw"+model.email);
        console.log(model);
        _model.role = model.role;
        _model.user_email = model.email;
        _model.id = model.id;
        _model.UKeyKunde = model.kundeObj.UKeyKunde;
        if(!model.lastName || model.lastName.length < 1){
            _model.lastName = " ";
        }
        if(!model.firstName || model.firstName.length < 1){
            _model.firstName = " ";
        }
        if(model.email !== ""){

            let sites = JSON.parse(process.env.VUE_APP_SYNC_SITES);
            for(let i=0; i < sites.length; i++) {
                console.log("sync " + sites[i]);
                APIServiceWP.setAPIClient(sites[i]);
                await APIServiceWP.setUserPassword(_model);
            }
            return true;
        }
    },

    async getAllSammelgruppenForKunde(id, typ){
        let gruppen = await gruppemitgliedService.getGruppenForKunde(id, typ);
        console.log("gruppen:");
        console.log(gruppen);
        let sammelgruppen = [];
        for(let i=0; i < gruppen.length; i++){
            let sgtmp = await sammelgruppeService.getSammelgruppenForGruppe(gruppen[i].id);
            console.log(sgtmp);
            for(let k=0; k < sgtmp.length; k++) {
                let found = false;
                for(let j=0; j<sammelgruppen.length; j++) {
                    if (sgtmp[k].id == sammelgruppen[j].id) {
                        found = true;
                    }
                }
                if(!found){
                    sammelgruppen.push(sgtmp[k]);
                }
            }
        }
        return sammelgruppen;
    },
    async getAllGruppenForKunde(id, typ){
        let gruppen = await gruppemitgliedService.getGruppenForKunde(id, typ);
        console.log("gruppen:");
        console.log(gruppen);
        return gruppen;
    },
    async saveContact(model){
        await telefonService.saveAllTelefonFromUserModel(model);
        return model;
    },

    async getContext(model, newUser = false, testmode = false){
        if(testmode){
            model.allLand = await APIServiceTMPSMP.getAllModel("land");
        }else if(model.schemaType !== "kunde_RF"){
            model.allLand = await APIService.getAllModel("land");
        }else{
            model.allLand = [];
        }
        for(let i=0; i < model.allLand.length; i++){
            model.allLand[i].name = model.allLand[i].Land;
        }

        if(newUser){
            if(model.KundeTyp === "F")
                model.schema = this.updateSchemaFirma(model.schema, model);
            else
                model.schema = this.updateSchemaPerson(model.schema, model);
            return model;
        }

        if(model.KundeTyp === "F"){
            model = await this.getAPsForModel(model, testmode);
            console.log("got aps");
            model.schema = this.updateSchemaFirma(model.schema, model);
            if(!model.berufsgruppe) model.berufsgruppe = {};
        }else{
            model.schema = this.updateSchemaPerson(model.schema, model);
            //Firma AP
            model.firmen = await kundeapService.getFirmenForKunde(model);
        }
        model = await this.getLandForModel(model, testmode);
        model = await this.getInnungForModel(model);

        //gruppen
        model.gruppen = {};
        if(!model.id){
            model.gruppen.kunde = "";
            model.gruppen = [];
            return model;
        }
        model.gruppen.kunde = model.id;
        model.gruppen.type = "kunde";
        model.gruppen.KundeTyp = model.KundeTyp;
        model.gruppen.InternetUser = model.InternetUser;
        console.log(model.KundeTyp);
        model.gruppen.gruppen = await gruppemitgliedService.getGruppenForKunde(model.id, model.KundeTyp);

        //telefon
        model = await telefonService.getAllTelefonForUserModel(model);

        return model;
    },

    async getAPsForModel(model, testmode = false){
        let aps = {};
        if(testmode) {
            aps = await APIServiceTMPSMP.getKundeApsForFirma(model.UKeyKunde);
        }else{
            console.log("get aps for firma");
            let filter = [{ name: "F_Kunde", value: model.id}, {name: "deep", value: true}];
            aps = await APIService.getAllModel("kundeap", filter)
            for(let i=0; i < aps.length; i++){
                if(aps[i].P_Kunde.length > 0){
                    aps[i].P_Kunde_Obj = aps[i].P_Kunde[0];
                    aps[i].P_Kunde = aps[i].P_Kunde_Obj._id;
                }
                if(aps[i].F_Kunde.length > 0){
                    aps[i].F_Kunde_Obj = aps[i].F_Kunde[0];
                    aps[i].F_Kunde = aps[i].F_Kunde_Obj._id;
                }
                for(let j=0; j < aps[i].Telefon.length; j++){
                    if(aps[i].Telefon[j].TelefonNr !== "" && aps[i].Telefon[j].TelefonTyp === "T") {
                        switch(aps[i].Telefon[j].TelefonTyp){
                            case telefonService.TelefonTyp.Telefon:{
                                if(!aps[i].TelefonNr || aps[i].TelefonNr === "")
                                    aps[i].TelefonNr = aps[i].Telefon[j].TelefonNr;
                                break;
                            }
                            case telefonService.TelefonTyp.EMail:{
                                if(!aps[i].EMail || aps[i].EMail === "")
                                    aps[i].EMail = aps[i].Telefon[j].TelefonNr;
                                break;
                            }
                            case telefonService.TelefonTyp.Mobil:{
                                if(!aps[i].Mobil || aps[i].Mobil === "")
                                    aps[i].Mobil = aps[i].Telefon[j].TelefonNr;
                                break;
                            }
                            case telefonService.TelefonTyp.Webseite:{
                                if(!aps[i].Webseite || aps[i].Webseite === "")
                                    aps[i].Webseite = aps[i].Telefon[j].TelefonNr;
                                break;
                            }
                        }

                    }
                }
            }
            //aps = await APIService.getKundeApsForFirma(model.UKeyKunde);
        }
        model.aps = aps;
        return model;
    },

    async getLandForModel(model, testmode){
        if(model.Land && model.Land !== ""){
            if(testmode){
                model.land = await APIServiceTMPSMP.getLand(model.UKeyLand);
            }else{
                model.land = await  APIService.getModel("land", model.Land);
            }
        }
        return model;
    },

    async getInnungForModel(model){

        // Überprüfen, ob model.Innung ein Objekt ist und eine id hat
        if(model.Innung && typeof model.Innung === 'object' && model.Innung.id){
            // Verwenden Sie model.Innung.id für die API-Anfrage
            model.Innung = await APIService.getModel("innung", model.Innung.id);
        }
        // Überprüfen, ob model.Innung ein nicht-leerer String ist
        else if(typeof model.Innung === 'string' && model.Innung !== ""){
            model.Innung = await APIService.getModel("innung", model.Innung);
        } else {
            model.Innung = "nicht bekannt";
        }
        return model;
    },

    updateSchemaPerson(schema, model){
        if(schema == null){
            return null;
        }
        for(let i=0; i < schema.groups.length; i++){
            if(schema.groups[i].slug == "ansprechpartner"){
                schema.groups.splice(i, 1);
            }
            this.updateSchemaField(schema.groups[i], model.allLand);
        }
        if (model.id == undefined || model.id == "") {
            for(let i=0; i < schema.groups.length; i++) {
                if (schema.groups[i].slug == "gruppen") {
                    schema.groups.splice(i, 1);
                }
            }
        }
        return schema;
    },

    updateSchemaFirma(schema, model){
        if(schema == null){
            return null;
        }

        for(let i=0; i < schema.groups.length; i++){
            schema.groups[i] = this.updateSchemaField(schema.groups[i], model.allLand);
        }
        if (model.id === undefined || model.id === "") {
            for(let i=0; i < schema.groups.length; i++) {
                if (schema.groups[i].slug === "gruppen") {
                    schema.groups.splice(i, 1);
                }
            }
        }
        if(model.schemaType === "kunde_RF"){
            for(let i=0; i < schema.groups.length; i++) {
                if (schema.groups[i].slug === "berufsgruppe") {
                    schema.groups.splice(i, 1);
                }
            }
            model.berufsgruppe = {};
            model.berufsgruppe.Berufsgruppe = "Handwerksbetrieb";
        }
        return schema;
    },

    updateSchemaField(group, allLand){
        for(let i=0; i<group.fields.length; i++){
            if(group.fields[i].model == "land.id"){
                group.fields[i].values = allLand;
            }
        }
        return group;
    },
    async deleteModel(model){
        console.log(model);
        await gruppemitgliedService.deleteGruppenForKunde(model);
        let res = await APIService.deleteModel("kunde", model.id)
        return res;
    },
    getSchemaTmpP(){
        console.log("get schema P");
        let obj = {
            groups:[
                {
                    legend:"Ansprechpartner",
                    slug: "ansprechpartner",
                    fields:[{
                        type:"apListField",
                        label:"Liste",
                        model: "aps"
                    }
                    ],
                },{
                    legend:"Gruppenzuordnung",
                    slug:"gruppen",
                    fields:[{
                        type:"GruppenListField",
                        label:"Gruppen",
                        model: "gruppen"
                    }]
                },{
                    legend:"Kontakt",
                    slug:"kontakt",
                    fields:[{
                        type:"input",
                        inputType:"phone",
                        label:"Telefon",
                        model:"telefon",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    },{
                        type:"input",
                        inputType:"phone",
                        label:"Mobil",
                        model:"mobil",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    },{
                        type:"input",
                        inputType:"email",
                        label:"E-Mail",
                        model:"email",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    },{
                        type:"input",
                        inputType:"text",
                        label:"Webseite",
                        model:"webseite",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }
                    ]
                },{
                    legend:"Person",
                    slug: "kunde",
                    fields:[{
                        type: "image",
                        label: "Bild/Logo",
                        model: "bild",
                        styleClasses: "s3m_input_image",
                        required: false,
                        readonly:true,
                        disabled:true,
                        visible: false
                    },{
                        type:"input",
                        inputType:"text",
                        label:"IdRutAuto (readonly)",
                        model:"IdRutAuto",
                        styleClasses: "s3m_input_text_ro",
                        required: false,
                        readonly:true,
                        disabled:true,
                        visible: false
                    },{
                        type:"input",
                        inputType:"text",
                        label:"UKeyInnung",
                        model:"UKeyInnung",
                        styleClasses: "s3m_input_text_ro",
                        required: false,
                        readonly:true,
                        disabled:true,
                        visible:false,
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"KundenNr",
                        model:"KundenNr",
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly: false,
                        disabled:false,
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Kurzbezeichnung",
                        model:"Kurzbezeichnung",
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly: false,
                        disabled:false,
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Archiv",
                        model:"Archiv",
                        styleClasses: "s3m_input_text_ro",
                        required: false,
                        readonly: true,
                        disabled: true,
                        visible: false
                    }, {
                        type: "select",
                        model: "KundeTyp",
                        label: "KundeTyp",
                        default: "P",
                        placeholder: "Select your favorite library",
                        selectOptions: {
                        },
                        values: [
                            {
                                "id": "P",
                                "name": "Person"
                            },
                            {
                                "id": "F",
                                "name": "Firma"
                            }
                        ],
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly:false,
                        disabled:false,
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Firma",
                        model:"Firma",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"select",
                        label:"Anrede",
                        model:"Anrede",
                        default: "keine",
                        selectOptions: {
                        },
                        values: [
                            {
                                "id": "keine",
                                "name": ""
                            },
                            {
                                "id": "Herr",
                                "name": "Herr"
                            },
                            {
                                "id": "Frau",
                                "name": "Frau"
                            }
                        ],
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Titel",
                        model:"Titel",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"AkademischerGrad",
                        model:"AkademischerGrad",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Vorname",
                        model:"Vorname",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Nachname1",
                        model:"Nachname1",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Nachname2",
                        model:"Nachname2",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Nachname3",
                        model:"Nachname3",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Postfach",
                        model:"Postfach",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"PLZ_PF",
                        model:"PLZ_PF",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Strasse",
                        model:"Strasse",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"PLZ_Str",
                        model:"PLZ_Str",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Ort",
                        model:"Ort",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    },{
                        type:"input",
                        inputType:"text",
                        label:"Land",
                        model:"land.Land",
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly:true,
                        disabled:true,
                        visible: false
                    }, {
                        type: "select",
                        label:"Land",
                        model:"land.id",
                        default: "Deutschland",
                        placeholder: "",
                        selectOptions: {
                        },
                        values: "allLand",
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly:false,
                        disabled:false,
                        visible: true
                    }, {
                        type:"input",
                        inputType:"date",
                        label:"Geburtstag",
                        model:"Geburtstag",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"KurzbezeichnungManuell",
                        model:"KurzbezeichnungManuell",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"select",
                        label:"InternetExportJaNein",
                        model:"InternetExportJaNein",
                        selectOptions: {
                        },
                        values: [
                            {
                                "id": "Ja",
                                "name": "Ja"
                            },
                            {
                                "id": "Nein",
                                "name": "Nein"
                            }
                        ],
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"email",
                        label:"InternetUser",
                        model:"InternetUser",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"InternetUserMaster",
                        model:"InternetUserMaster",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"GUID",
                        model:"GUID",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Breitengrag",
                        model:"Geodat_Breite",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Längengrad",
                        model:"Geodat_Laenge",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Geodat_Checked",
                        model:"Geodat_Checked",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Bankname",
                        model:"Bankname",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"IBAN",
                        model:"IBAN",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"BIC",
                        model:"BIC",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"UKeyKunde",
                        model:"UKeyKunde",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestTimestampCreate",
                        model:"RestTimestampCreate",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestUserCreate",
                        model:"RestUserCreate",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestTimestampModify",
                        model:"RestTimestampModify",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestUserModify",
                        model:"RestUserModify",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestTimestampDelete",
                        model:"RestTimestampDelete",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestUserDelete",
                        model:"RestUserDelete",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }]
                }]
        }
        console.log(obj);
        return obj;
    },
    getSchemaTmpF(){
        console.log("get schema P");
        let obj = {
            groups:[
                {
                    legend:"Ansprechpartner",
                    slug: "ansprechpartner",
                    fields:[{
                        type:"apListField",
                        label:"Liste",
                        model: "aps"
                    }
                    ],
                },{
                    legend:"Gruppenzuordnung",
                    slug:"gruppen",
                    fields:[{
                        type:"GruppenListField",
                        label:"Gruppen",
                        model: "gruppen"
                    }]
                },{
                    legend:"Kontakt",
                    slug:"kontakt",
                    fields:[{
                        type:"input",
                        inputType:"phone",
                        label:"Telefon",
                        model:"telefon",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    },{
                        type:"input",
                        inputType:"phone",
                        label:"Mobil",
                        model:"mobil",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    },{
                        type:"input",
                        inputType:"email",
                        label:"E-Mail",
                        model:"email",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    },{
                        type:"input",
                        inputType:"text",
                        label:"Webseite",
                        model:"webseite",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }
                    ]
                },{
                    legend:"Firma",
                    slug: "kunde",
                    fields:[{
                        type: "image",
                        label: "Bild/Logo",
                        model: "bild",
                        styleClasses: "s3m_input_image",
                        required: false,
                        readonly:true,
                        disabled:true,
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"SOKA Mitglied",
                        model:"soka",
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly: true,
                        disabled:false,
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Präfix Firma",
                        model:"praefix",
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly: true,
                        disabled:false,
                        visible: true
                    },{
                        type:"input",
                        inputType:"text",
                        label:"IdRutAuto (readonly)",
                        model:"IdRutAuto",
                        styleClasses: "s3m_input_text_ro",
                        required: false,
                        readonly:true,
                        disabled:true,
                        visible: false
                    },{
                        type:"input",
                        inputType:"text",
                        label:"UKeyInnung",
                        model:"UKeyInnung",
                        styleClasses: "s3m_input_text_ro",
                        required: false,
                        readonly:true,
                        disabled:true,
                        visible:true,
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"KundenNr",
                        model:"KundenNr",
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly: false,
                        disabled:false,
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Kurzbezeichnung",
                        model:"Kurzbezeichnung",
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly: false,
                        disabled:false,
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Archiv",
                        model:"Archiv",
                        styleClasses: "s3m_input_text_ro",
                        required: false,
                        readonly: true,
                        disabled: true,
                        visible: false
                    }, {
                        type: "select",
                        model: "KundeTyp",
                        label: "KundeTyp",
                        default: "P",
                        placeholder: "Select your favorite library",
                        selectOptions: {
                        },
                        values: [
                            {
                                "id": "P",
                                "name": "Person"
                            },
                            {
                                "id": "F",
                                "name": "Firma"
                            }
                        ],
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly:false,
                        disabled:false,
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Firma",
                        model:"Firma",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"select",
                        label:"Anrede",
                        model:"Anrede",
                        default: "keine",
                        selectOptions: {
                        },
                        values: [
                            {
                                "id": "keine",
                                "name": ""
                            },
                            {
                                "id": "Herr",
                                "name": "Herr"
                            },
                            {
                                "id": "Frau",
                                "name": "Frau"
                            }
                        ],
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Titel",
                        model:"Titel",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"AkademischerGrad",
                        model:"AkademischerGrad",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Vorname",
                        model:"Vorname",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Nachname1",
                        model:"Nachname1",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Nachname2",
                        model:"Nachname2",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Nachname3",
                        model:"Nachname3",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Postfach",
                        model:"Postfach",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"PLZ_PF",
                        model:"PLZ_PF",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Strasse",
                        model:"Strasse",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"PLZ_Str",
                        model:"PLZ_Str",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Ort",
                        model:"Ort",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    },{
                        type:"input",
                        inputType:"text",
                        label:"Land",
                        model:"land.Land",
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly:true,
                        disabled:true,
                        visible: false
                    }, {
                        type: "select",
                        label:"Land",
                        model:"land.id",
                        default: "Deutschland",
                        placeholder: "",
                        selectOptions: {
                        },
                        values: "allLand",
                        styleClasses: "s3m_input_text",
                        required: false,
                        readonly:false,
                        disabled:false,
                        visible: true
                    }, {
                        type:"input",
                        inputType:"date",
                        label:"Geburtstag",
                        model:"Geburtstag",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"KurzbezeichnungManuell",
                        model:"KurzbezeichnungManuell",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"select",
                        label:"InternetExportJaNein",
                        model:"InternetExportJaNein",
                        selectOptions: {
                        },
                        values: [
                            {
                                "id": "Ja",
                                "name": "Ja"
                            },
                            {
                                "id": "Nein",
                                "name": "Nein"
                            }
                        ],
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"email",
                        label:"InternetUser",
                        model:"InternetUser",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"InternetUserMaster",
                        model:"InternetUserMaster",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"GUID",
                        model:"GUID",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Breitengrag",
                        model:"Geodat_Breite",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Längengrad",
                        model:"Geodat_Laenge",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Geodat_Checked",
                        model:"Geodat_Checked",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Bankname",
                        model:"Bankname",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"IBAN",
                        model:"IBAN",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"BIC",
                        model:"BIC",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"Umsatzsteuer ID",
                        model:"Umsatzsteuer_ID",
                        readonly:false,
                        disabled:false,
                        styleClasses: "s3m_input_text",
                        visible: true
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"UKeyKunde",
                        model:"UKeyKunde",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestTimestampCreate",
                        model:"RestTimestampCreate",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestUserCreate",
                        model:"RestUserCreate",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestTimestampModify",
                        model:"RestTimestampModify",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestUserModify",
                        model:"RestUserModify",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestTimestampDelete",
                        model:"RestTimestampDelete",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }, {
                        type:"input",
                        inputType:"text",
                        label:"RestUserDelete",
                        model:"RestUserDelete",
                        readonly:true,
                        disabled:true,
                        styleClasses: "s3m_input_text_ro",
                        visible: false
                    }]
                }]
        }
        console.log(obj);
        return obj;
    }
}
