<template>
  <div class="s3m-list_select">
   <v-row justify="center">
    <OverlayDialog
        v-model="showDialog"
        persistent
        max-width="800px"
        style="z-index:100"
        v-on:close="this.close"
        :show-save-button="false"
        content-class="s3m-list_select_dialog"
    >
    <h1>{{title}}</h1>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="list.length < 5"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              v-if="!item.userHasGroup"
              small
              @click="addToGroup(item)"
          >
            mdi-plus
          </v-icon>
          <v-icon
              v-if="item.userHasGroup"
              small
              @click="removeFromGroup(item)"
          >
            mdi-minus
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <confirm-dialog ref="confirmDelete"/>
      <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
    </OverlayDialog>
  </v-row>
  </div>
</template>

<script>
//import APIService from "@/services/APIService";
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import OverlayDialog from "@/components/OverlayDialog.vue";
import modelService from "@/services/model.service";
import gruppemitgliedService from "@/services/gruppemitglied.service";
//import userService from "@/services/user.service";
export default {
  name: "ListSelectDialog",
  mixins: [vuetifyTableMixin],
  components: {
    OverlayDialog,
    ConfirmDialog
  },
  data() {
    return {
      sortBy: 'Bezeichnung',
      sortDesc: false,
      showDialog: false,
      showLoader: false,
      type: "",
      modelType: "",
      item: {},
      search: '',
      title: '',
      list: [],
      userGruppen: [],
      headers: [
        { text: 'Bezeichnung', value: 'Bezeichnung' },
        { text: 'Kurzbezeichnung', value: 'Kurzbezeichnung' },
        { text: 'Gruppenmitglied', value: 'userHasGroupTxt'},
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        },
      ]
    }
  },
  methods: {
    show(type, item){
      console.log("SHOW GRUPPEN "+type);
      console.log(item);
      this.type = type;
      this.model = item;
      this.showDialog = true;
      this.modelType = item.type;
      this.fetchModel(type);
      this.userGruppen = item.gruppen;
    },
    close(){
      this.showDialog = false;
    },
    async addToGroup(item){
      console.log("add to group");
      this.showLoader = true;
      let model = this.createModel(item);
      model.InternetUser = this.model.InternetUser;
      await modelService.saveModel(model, "gruppemitglied");
      await this.fetchModel(this.type);
      this.$root.$emit('updateList', "usergruppen");
      this.showLoader = false;
    },
    async removeFromGroup(item){
      console.log("remove from group");
      this.showLoader = true;
      console.log(item);
      item.Gruppemitglied.InternetUser = this.model.InternetUser;
      item.Gruppemitglied.Gruppe = item.id;
      await modelService.deleteModel(item.Gruppemitglied, "gruppemitglied");
      await this.fetchModel(this.type);
      this.$root.$emit('updateList', "usergruppen");
      this.showLoader = false;
    },
    createModel(gruppe){
      const current = new Date();
      let month = current.getMonth()+1;
      month = (month < 10) ? '0'+month : month;
      let day = current.getDate();
      day = day < 10 ? '0'+day : day;
      const date = `${current.getFullYear()}-${month}-${day}`;
      let model = {};
      if(this.modelType == "kundeap"){
        model = {
          Gruppe: gruppe.id,
          GruppeFunktion: "643562aa7304db0027195b0a",
          F_Kunde: this.model.firma,
          P_Kunde: this.model.kunde,
          Datum: date
        };
      }else{
        if(this.model.KundeTyp == "F"){
          model = {
            Gruppe: gruppe.id,
            GruppeFunktion: "643562aa7304db0027195b0a",
            F_Kunde: this.model.kunde,
            Datum: date
          };
        }else{
          model = {
            Gruppe: gruppe.id,
            GruppeFunktion: "643562aa7304db0027195b0a",
            P_Kunde: this.model.kunde,
            Datum: date
          };
        }

      }

      return model;
    },
    async fetchModel(type){

      this.showLoader = true;
      console.log("fetch gruppen for "+this.modelType);
      if(this.modelType == "kundeap"){
        console.log("fetch gruppen for kundeap 1 ");
        console.log(this.model);
        this.model.gruppen = await gruppemitgliedService.getGruppenForKundeAndFirma(this.model.kunde, this.model.firma);

      }else{
        console.log(" get kunde without firma *******");
        this.model.gruppen = await gruppemitgliedService.getGruppenForKunde(this.model.kunde, this.model.KundeTyp);
      }


      //let sgs = await userService.getAllSammelgruppenForKunde(this.model.kunde, this.model.KundeTyp);
      //console.log(sgs);
      this.userGruppen = this.model.gruppen;
      this.list = await modelService.getModel(type);


      for(let i=0; i < this.list.length; i++){
        for(let j = 0; j < this.userGruppen.length; j++){
          if(this.userGruppen[j].id == this.list[i].id){
            this.list[i].userHasGroup = true;
            this.list[i].userHasGroupTxt = "Ja";
            this.list[i].Gruppemitglied = this.userGruppen[j].Gruppemitglied;
            //console.log(this.userGruppen[j].gruppemitglied);
          }
        }
      }
      this.showLoader = false;
      //this.$refs.dataTable.refresh();
    }
  },
  /*
  computed: {
    ...mapState(['users'])
  },*/
  watch:{
    $route (){
      //this.entered();
    }
  },
  created() {
    //this.entered();
  }
}
</script>

<style scoped>

</style>
