<template>
  <div class="berfusgruppe_form">
    <select class="select-style" v-model="berufsgruppe" @change="onBerufsgruppeChange">
      <option disabled value="">Bitte wählen</option>
      <option v-for="option in berufsgruppenOptionen" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </select>

    <select @change="onSetBerufsGruppe" class="select-style" v-if="berufsgruppe === 'Handwerksbetrieb'" v-model="handwerksbetrieb">
      <option disabled value="">Bitte wählen</option>
      <option v-for="option in handwerksbetriebOptionen" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </select>

    <input @change="onSetBerufsGruppe" class="s3m_input_text" type="text" v-if="berufsgruppe === 'Sachverständigenbüro'" v-model="fuerDasGewerk" placeholder="Für das Gewerk...">

    <select @change="onSetBerufsGruppe" class="select-style" v-if="berufsgruppe === 'Organisation'" v-model="organisation">
      <option disabled value="">Bitte wählen</option>
      <option v-for="option in organisationsOptionen" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </select>

    <select @change="onSetBerufsGruppe" class="select-style" v-if="berufsgruppe === 'Schule'" v-model="schule">
      <option disabled value="">Bitte wählen</option>
      <option v-for="option in schulenOptionen" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </select>

    <input @change="onSetBerufsGruppe" class="s3m_input_text" type="text" v-if="berufsgruppe === 'Sonstiges'" v-model="sonstige" placeholder="Bitte angeben...">
  </div>
</template>

<script>
import {abstractField} from "vue-form-generator";

export default {
  name: 'BerufsgruppeWahlField',
  data() {
    return {
      berufsgruppe: '',
      handwerksbetrieb: '',
      fuerDasGewerk: '',
      organisation: '',
      schule: '',
      sonstige: '',
      berufsgruppenOptionen: [
        { value: 'Architekturbüro/ Planungsbüro', label: 'Architekturbüro/ Planungsbüro' },
        { value: 'Sachverständigenbüro', label: 'Sachverständigenbüro' },
        { value: 'Handwerksbetrieb', label: 'Handwerksbetrieb' },
        { value: 'Organisation', label: 'Organisation' },
        { value: 'Schule', label: 'Schule' },
        { value: 'Industrie', label: 'Industrie' },
        { value: 'Baustoffhandel', label: 'Baustoffhandel' },
        { value: 'Meisterschüler', label: 'Meisterschüler' },
        { value: 'Sonstiges', label: 'Sonstiges' },
      ],
      handwerksbetriebOptionen: [
        { value: 'Stuckateur', label: 'Stuckateur' },
        { value: 'Maler', label: 'Maler' },
        { value: 'Sonstige', label: 'Sonstige' },
      ],
      organisationsOptionen: [
        { value: 'Verband', label: 'Verband' },
        { value: 'Kreishandwerkerschaft', label: 'Kreishandwerkerschaft' },
        { value: 'Kammer', label: 'Kammer' },
        { value: 'Sonstige', label: 'Sonstige' },
        // weitere Optionen
      ],
      schulenOptionen: [
        { value: 'Berufsschule', label: 'Berufsschule' },
        { value: 'Meisterschule', label: 'Meisterschule' },
        { value: 'Sonstige', label: 'Sonstige' },
      ],
    };
  },
  mixins: [ abstractField ],
  methods: {
    onBerufsgruppeChange() {
      this.setBerufsgruppe(this.berufsgruppe);
      this.handwerksbetrieb = '';
      this.fuerDasGewerk = '';
      this.organisation = '';
      this.schule = '';
    },
    onSetBerufsGruppe(event){
      this.setBerufsgruppe(event.target.value);
    },
    setBerufsgruppe(gruppe) {
      if(gruppe === this.berufsgruppe) gruppe = "";
      this.choosenBerufsgruppe = { Berufsgruppe: this.berufsgruppe, Untergruppe: gruppe};
      console.log(this.choosenBerufsgruppe);
      this.value = {...this.choosenBerufsgruppe};
      console.log(this.value);
    }
  },
};
</script>

<style scoped>
/* Stile für deine Formularkomponente */
</style>
