import APIService from "@/services/APIService";
import sammelgruppeService from "@/services/sammelgruppe.service";

export default {
    // eslint-disable-next-line no-unused-vars
    async getModel(id = "", schema = [], filter = []){
        if(id != ""){
            let model = await APIService.getModel("gruppe", id);
            model.schema = schema;
            return model;
        }else{
            let model = await APIService.getAllModel("gruppe", filter);
            for(let i=0; i < model.length; i++){
                model[i].schema = schema;
            }
            return model;
        }
    },
    async saveModel(model){
        console.log("save model gruppe");
        console.log(model);
        const _model = { ...model }

        //prepare for save
        _model.GruppeTyp = model.GruppeTyp.id;
        delete _model.allGruppeTyp;
        delete _model.schema;
        delete _model.created;
        delete _model.schemaType;

        //Rest
        delete _model.RestTimestampCreate;
        delete _model.RestTimestampLast;

        if(!_model.AllowSelfJoin){
            _model.AllowSelfJoin = false;
        }

        let res;
        if(!model.id){
            console.log("create gruppe");
            res = await APIService.createModel("gruppe", _model);
        }else {
            delete _model.id;
            delete _model.UKeyGruppe;
            console.log("update gruppe "+model.id);
            res = await APIService.updateModel("gruppe", _model, model.id);
        }
        if(res.status == 200){
            console.log("success on save");
            //await this.syncModel(model, id);
        }
        return res;
    },

    async getContext(model){

        console.log("get context");

        model.allGruppeTyp = await APIService.getAllModel("gruppetyp");
        for(let i=0; i < model.allGruppeTyp.length; i++) {
            //model.allGruppeTyp[i].id = model.allGruppeTyp[i].id;
            model.allGruppeTyp[i].name = model.allGruppeTyp[i].Name;

        }

        model.schema = this.updateSchema(model.schema, model.allGruppeTyp);
        model = await this.getGruppeTypForModel(model);

        return model;
    },


    async syncGruppe(id, InternetUser = null, add = true){
        console.log("sync gruppe");
        let sammelgruppen = await this.getSammelGruppen(id);
        console.log(sammelgruppen);
        if(sammelgruppen.length > 0){
            for(let i=0; i < sammelgruppen.length; i++){
                console.log(InternetUser);
                if (InternetUser && typeof InternetUser === 'string' && InternetUser.length >= 5) {
                    console.log("single user");
                    await sammelgruppeService.syncSingleUser(sammelgruppen[i], InternetUser, add);
                }else{
                    await sammelgruppeService.syncModel(sammelgruppen[i], sammelgruppen[i].id, true);
                }

            }
        }
    },

    async getSammelGruppen(id){
        let sammelgruppen = await sammelgruppeService.getSammelgruppenForGruppe(id);
        console.log(sammelgruppen);
        return sammelgruppen;
    },

    async getGruppeTypForModel(model){
        if(typeof model.GruppeTyp == "object") return model;
        if(model.GruppeTyp && model.GruppeTyp != ""){
            let gruppeTyp = await APIService.getModel("gruppetyp", model.GruppeTyp);
            model.GruppeTyp = gruppeTyp;
        }else{
            model.GruppeTyp = model.allGruppeTyp[2];
        }
        return model;
    },

    updateSchema(schema, allGruppeTyp){
        if(schema == null){
            return null;
        }
        for(let i=0; i < schema.groups.length; i++){
            if(schema.groups[i].slug == ""){
                schema.groups.splice(i, 1);
            }
            schema.groups[i] = this.updateSchemaField(schema.groups[i], allGruppeTyp);
        }
        return schema;
    },

    updateSchemaField(group, allGruppeTyp){
        for(let i=0; i<group.fields.length; i++){
            //console.log(group.fields[i]);
            if(group.fields[i].model == "GruppeTyp.id"){
                group.fields[i].values = allGruppeTyp;
            }
        }
        return group;
    }
}
