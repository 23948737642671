import userService from '@/services/user.service';
import kundeApService from '@/services/kundeap.service';
import telefonService from '@/services/telefon.service';
import sammelgruppeService from "@/services/sammelgruppe.service";
import gruppeService from "@/services/gruppe.service";
import gruppemitgliedService from "@/services/gruppemitglied.service";

export default {
    async getModel(type, schema, id = "", testmode = false) {
        switch (type) {
            case "kunde": {
                const model = await userService.getModel(id, schema, testmode)
                return model;
            }
            case "kundeap": {
                const model = await kundeApService.getModel(id, schema, testmode)
                return model;
            }
            case "telefon": {
                const model = await telefonService.getModel(id, schema, testmode)
                return model;
            }
            case "land": {
                const model = await userService.getLand(id, schema, testmode)
                return model;
            }
            case "gruppe": {
                const model = await gruppeService.getModel(id, schema, testmode);
                return model;
            }
            case "sammelgruppe":{
                const model = await sammelgruppeService.getModel(id, schema, testmode);
                return model
            }
        }
    },
    async saveModel(model, type, sg_id, oldModel = null) {
        console.log("save "+type);
        switch (type) {
            case "kunde": {
                return await userService.saveModel(model, oldModel, model.id);
            }
            case "kundeap": {
                return await kundeApService.saveModel(model, oldModel);
            }
            case "telefon": {
                break;
            }
            case "land": {
                break;
            }
            case "gruppemitglied": {
                console.log("go on");
                return gruppemitgliedService.saveModel(model);
            }
            case "gruppe":{
                return await gruppeService.saveModel(model, model.id);
            }
            case "sammelgruppe": {
                return await sammelgruppeService.saveModel(model, sg_id, false);
            }
        }
    },
    async deleteModel(model, type) {
        console.log(type);
        switch (type) {
            case "kunde": {
                return await userService.deleteModel(model);
            }
            case "kundeap": {
                return await kundeApService.deleteModel(model);
            }
            case "telefon": {
                break;
            }
            case "land": {
                break;
            }
            case "gruppe":{
                break;
            }
            case "gruppemitglied":{
                return gruppemitgliedService.deleteModel(model)
            }
            case "sammelgruppe": {
                return await sammelgruppeService.deleteSammelgruppe(model)
            }
        }
    },
    async getContext(model, type) {
        switch (type) {
            case "kunde": {
                return await userService.getContext(model);
            }
            case "kundeap": {
                return await kundeApService.getContext(model);
            }
            case "telefon": {
                break;
            }
            case "land": {
                break;
            }
            case "gruppe": {
                return await gruppeService.getContext(model);
            }
            case "sammelgruppe": {
                return await sammelgruppeService.getContext(model);
            }
        }
    },
    async getSchemaTmp(type) {
        console.log(type);
        switch (type) {
            case "kunde_P": {
                return userService.getSchemaTmpP();
            }
            case "kunde_F": {
                return userService.getSchemaTmpF();
            }
            case "kundeap": {
                break;
            }
            case "telefon": {
                break;
            }
            case "land": {
                break;
            }
            case "gruppe": {
                break;
            }
            case "sammelgruppe": {
                break;
            }
        }
    }
}
