<template>
  <div class="s3m-gruppen_list_field">
    <div>
      <ul
          class="form-control"
      >
        <li v-for="item in list" :key="item.id" v-on:click="showGruppe(item)">{{ item.Bezeichnung }}</li>
      </ul>

      <v-btn v-if="(true || localhost)" style="margin-top:40px;margin-bottom: 40px;" v-on:click="edit" color="primary">
        Gruppen bearbeiten
      </v-btn>

    </div>
    <confirm-dialog ref="confirmDelete"/>
    <list-select-dialog ref="listSelectDialog" />
    <gruppen-user-select-dialog ref="GruppenUserSelectDialog" />
  </div>
</template>

<script>
import { abstractField } from "vue-form-generator";
import ListSelectDialog from "@/components/ListSelectDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import GruppenUserSelectDialog from "@/components/GruppenUserSelectDialog.vue";
//import ListSelectDialog from "@/components/ListSelectDialog.vue";

export default {
  name: "GruppenListField",
  components: {
    GruppenUserSelectDialog,
    ListSelectDialog,
    ConfirmDialog

  },
  data: () => ({
    list: [],
    localhost: false
  }),
  mixins: [ abstractField ],
  methods: {
    create(){
      console.log("created gruppen");

    },
    edit () {
      this.$refs.listSelectDialog.show("gruppe", this.value)
    },
    showGruppe(item){
      this.$refs.GruppenUserSelectDialog.show(item);
    }
  },
  mounted() {
    this.localhost = (window.location.hostname.includes('localhost'));
    if(!this.value){
      return;
    }
    this.list = this.value.gruppen.reduce((unique, item) => {
     return unique.some(uniqueItem => uniqueItem.id === item.id) ? unique : [...unique, item];
    }, []);
     this.$root.$off("updateList");
     this.$root.$on('updateList', (msg) => {
       console.log("update list "+msg);
       if(msg == 'usergruppen'){
         console.log("update user gruppen in user detail dialog");
       }
     });
    }
};
</script>
