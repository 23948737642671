<template>
  <div class="s3m-list_field">
    <div>
      <ul
          class="form-control"
      >
        <li v-for="item in list" :key="item">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
  name: "ListField",
  components: {
  },
  data: () => ({
    list: []
  }),
  mixins: [ abstractField ],
  methods: {
    create(){
      console.log("created aps");
    },
    edit (item) {
      console.log(item);
    },
  },
   mounted() {
       if(!this.value){
         return;
       }
       this.list = this.value;
    }
};
</script>
