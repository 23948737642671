import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import APIService from "@/services/APIService";
import userService from "@/services/user.service";
//import APIService_2 from "@/services/APIService_2";

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
  },
  state: {
    user: null,
    users: [],
    managedUsers: [],
    options: {
      itemsPerPage: 5
    },
    copyUser: null,
    currentModel: null,
    kunde: null,
    sammelgruppen: null,
    gruppen: null,
    editUser: null,
    loading: false,
  },
  getters: {
    isLoggedIn: (state) => {
      return state.user !== null
    },
    hasRole: (state) => (role) => {
      return state?.user?.role === role
    },
    accessToken: (state) => {
      return state?.user?.tokens?.access?.token
    },
    refreshToken: (state) => {
      return state?.user?.tokens?.refresh?.token
    }
  },
  mutations: {
    setLoading(state, payload) {
      console.log("setLoading ", payload);
      state.loading = payload;
    },
    clearEditUser (state) {
      state.editUser = null;
    },
    updateEditUser: (state, payload) => {
      state.editUser = payload
    },
    updateUser(state, data) {
      const {user, tokens} = data
      state.user = user
      state.user.tokens = tokens
    },
    updateTokens(state, tokens) {
      state.user = { ...state.user, tokens }
    },
    async logout(state) {
      state.user = null
    },
    updateUsers(state,users) {
      console.log("update users");
      console.log(users);
      this.state.users = users
    },
    updateManagers(state,managers) {
      state.user.managers = managers
    },
    updateManagedUsers(state,managedUsers) {
      console.log("update manages users");
      console.log(managedUsers);
      state.managedUsers = managedUsers
    },
    updateKunde(state, kunde){
      console.log(kunde)
      state.kunde = kunde;
      if(state.user){
        state.user.kundeObj = kunde;
      }
    },
    updateSammelgruppen(state, sgs){
      state.sammelgruppen = sgs;
      if(state.user){
        state.user.kundeObj.sammelgruppen = sgs;
      }
    },
    updateGruppen(state, gruppen){
      state.gruppen = gruppen;
      if(state.user){
        state.user.kundeObj.gruppen = gruppen;
      }
    },
    updateItemsPerPage(state, itemsPerPage) {
      state.options = { ...state.options, itemsPerPage }
    }
  },
  actions: {

    login({commit}, {email, password}){
      return APIService.login(email, password).then((user) => {
        console.log("Response", user)
        commit('updateUser', user)
      })
    },
    async logout({commit, getters}){

        // Weitere Logout-Logik in deiner Vuex-Anwendung
        if (getters.isLoggedIn) {
          await APIService.logout(store.getters.refreshToken);
          commit('logout');
        }
    },
    refreshToken({commit,state}) {
      const token = state?.user?.tokens?.refresh?.token ?? 'invalid'
      return APIService.refreshToken(token).then(tokens => {
        commit('updateTokens', tokens)
        return tokens
      })
    },
    fetchUsers({commit}) {
      return APIService.getUsers().then((users) => {
        commit('updateUsers', users)
      })
    },
    fetchManagedUsers({commit, state}) {
      return APIService.getManagedUsers(state.user.id).then((users) => {
        commit('updateManagedUsers', users)
      })
    },
    fetchKunde({commit, state}){
      return APIService.getKundeOrAPWithInternetUser(state.user.email).then((kunde) => {
        if(!kunde){
          return APIService.getKundeOrAPWithInternetUser(state.user.origMail).then((kunde) => {
            commit('updateKunde', kunde)
          })
        }else{
          commit('updateKunde', kunde)
        }
      })
    },
    fetchSammelgruppen({commit, state}){
      return userService.getAllSammelgruppenForKunde(state.user.kundeObj.id, state.user.kundeObj.KundeTyp).then((sammelgruppen) => {
        commit('updateSammelgruppen', sammelgruppen)
      })
    },
    fetchGruppen({commit, state}){
      return userService.getAllGruppenForKunde(state.user.kundeObj.id, state.user.kundeObj.KundeTyp).then((gruppen) => {
        commit('updateGruppen', gruppen)
      })
    }
  }
})

export default store
