//import APIService_2 from "@/services/APIService_2";
import APIService from "@/services/APIService";
import store from "../store/index"
import userService from "@/services/user.service";
import telefonService from "@/services/telefon.service";
import gruppemitgliedService from "@/services/gruppemitglied.service";
import utils from "@/utils/utils";

export default {
    // eslint-disable-next-line no-unused-vars
    async getModel(id = "", schema){
        console.log("id: "+id);
        if(id != ""){
            let model = await APIService.getKundeAp(id);
            model.schema = schema;
            return model;
        }else{
            let model = await APIService.getKundeAps();
            for(let i=0; i < model.length; i++){
                model[i].schema = schema;
            }
            return model;
        }
    },
    async getFirmenForKunde(model){
      let firmenIds = await  APIService.getAllModel('kundeap', [{name: 'P_Kunde', value: model.id}]);
      let firmen = [];
      for(let i=0; i< firmenIds.length; i++){
          let f = await userService.getModel(firmenIds[i].F_Kunde);
          firmen.push(f);
      }
      return firmen;
    },
    async getAPForGruppe(P_Kunde, F_Kunde){
        let res = await APIService.getAllModel('kundeap',[{name: 'F_Kunde', value: F_Kunde}, {name: 'P_Kunde', value: P_Kunde}])
        console.log(res);
        if(res.length > 0){
            return res[0];
        }else{
            return {};
        }
    },
    async getContext(model){
        console.log("get context ap");
        console.log(model);
        if(model._id !== undefined && model.id === undefined){
            model.id = model._id;
        }
        if(store.state.copyUser != null){
            model.P_Kunde = store.state.copyUser.id;
            model.user = store.state.copyUser;
        }else{
            model.user = await userService.getModel(model.P_Kunde);
            console.log(model.user);
        }
        model.P_Kunde_Name = "";
        if(model.P_Kunde_Obj){
            model.P_Kunde_Name = model.P_Kunde_Obj.KurzbezeichnungExtern || (model.P_Kunde_Obj.Adresse11 + " " + model.P_Kunde_Obj.Adresse12);
        }
        if(store.state.currentModel != null && (model.F_Kunde == null || model.F_Kunde === "") ){
            model.F_Kunde = store.state.currentModel.id;
        }

        console.log("model in get context kundeap");
        console.log(model);
        model.gruppen = {};
        model.gruppen.kunde = model.P_Kunde;
        model.gruppen.firma = model.F_Kunde;
        model.gruppen.type = "kundeap";
        model.gruppen.InternetUser = model.InternetUser;
        model.gruppen.gruppen = await gruppemitgliedService.getGruppenForKundeAndFirma(model.P_Kunde, model.F_Kunde);

        model = await telefonService.getAllTelefonForUserModel(model, true);

        return model;
    },
    async saveModel(model, oldModel){

        console.log("save model kundeap");
        console.log(model);
        const _model = { ...model }

        //prepare for save
        delete _model._id;
        delete _model.__v;
        delete _model.user;
        delete _model.schema;
        delete _model.test;
        delete _model.UKeyKundeAp;
        delete _model.created;
        delete _model.P_Kunde_Name;
        delete _model.importedAt;
        //Rest
        delete _model.RestTimestampCreate;
        delete _model.RestTimestampLast;
        //contact
        delete _model.telefon;
        delete _model.mobil;
        delete _model.email;
        delete _model.webseite;
        delete _model.Telefon;
        delete _model.telefonId;
        delete _model.mobilId;
        delete _model.emailId;
        delete _model.webseiteId;
        delete _model.suchstring;
        delete _model.completeName;
        delete _model.P_KundeObj;
        delete _model.F_KundeObj;

        //gruppen
        delete _model.gruppen;
        if(oldModel.InternetUser === model.InternetUser){
            //delete _model.InternetUser;
        }

        //aps
        delete _model.P_Kunde_Obj;
        delete _model.F_Kunde_Obj;

        if(model._id !== undefined && model.id === undefined){
            model.id = model._id;
        }

        if(!_model.AnredeErweiterung){
            _model.AnredeErweiterung = "-";
        }
        if(!_model.Abteilung){
            _model.Abteilung = " ";
        }
        if(model.Funktion == null || model.Funktion === "") _model.Funktion = " ";

        if ((model.InternetUser && model.InternetUser.trim() !== "") && !utils.validateEmail(model.InternetUser.trim())) {
            return {status: false, error: "invalid_email_internetuser"};
        }
        if ((model.email && model.email.trim() !== "") && !utils.validateEmail(model.email.trim())) {
            return {status: false, error: "invalid_email_contact"};
        }

        let res;
        if(!model.id && !model._id){
            console.log("create kundeap");
            res = await APIService.createModel("kundeap", _model);
            _model.id = res.id;
            model.id = res.id;
        }else {
            delete _model.id;
            delete _model.InternetUserMaster;
            console.log("update kundeap "+((model.id !== undefined) ? model._id : model._id));
            res = await APIService.updateModel("kundeap", _model, ((model.id !== undefined) ? model.id : model._id));
        }
        //save contact
        await this.saveContact(model);

        console.log(res);
        if(res != null && res.id !== ""){
            console.log("success on save kunde ap service");
            console.log(_model);
            // create internet user and sync?
            const _user = { ..._model }
            if(_user.P_Kunde && _user.P_Kunde !== ""){
               let kunde = await userService.getModel(_user.P_Kunde);
               _user.Vorname = kunde.Vorname;
               _user.Nachname1 = kunde.Nachname1;
               _user.KundeTyp = kunde.KundeTyp;
               if(!_user.id) _user.id = model.id;
            }
            await userService.setInternetUser(_user, _user.id, oldModel, _user)
        }
        return res;
    },
    async deleteModel(model){
        console.log("delete "+model.id);
        let res = await APIService.deleteModel("kundeap", model.id)
        if(model.InternetUser.trim() === "")
            await userService.deleteLoginUserByMail(model.InternetUser);
        console.log(res);
        return res;
    },
    async saveContact(model){
        await telefonService.saveAllTelefonFromUserModel(model, true);
        return model;
    },
}
