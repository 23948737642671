<template>
  <v-dialog v-bind="$attrs" content-class="s3m-overlay_dialog">
    <v-card>
      <v-toolbar style="position: sticky;  top: 0; z-index: 999;" dense fixed>
        <v-spacer></v-spacer>
        <v-btn v-if="this.showSaveButton" @click="$emit('save')" variant="flat" color="green" style="color:white;margin-right: 10px;">Speichern</v-btn>
        <v-btn @click="$emit('close')" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OverlayDialog",
  props: {
    showSaveButton: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    //console.log("show save button:" + this.showSaveButton);
  }
}
</script>

<style scoped>

</style>
