import {mapMutations, mapState} from "vuex";

export const vuetifyTableMixin = {
    methods: {
        ...mapMutations(['updateItemsPerPage']),
        updateOptions(options) {
            this.updateItemsPerPage(options.itemsPerPage)

        },
    },
    computed: {
        ...mapState(['options']),
        itemsPerPage() {
            return this.options.itemsPerPage
        }
    }
}
