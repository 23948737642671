import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from "@/store";
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
import './filters'
import VueFormGenerator from "vue-form-generator";
import ListField from "@/components/ListField.vue";
import apListField from "./components/APListField.vue";
import GruppenListField from "./components/GruppenListField.vue";
import Spinner from 'vue-spinkit'
import kundenListField from "@/components/KundenListField.vue";
import berufsgruppeWahlField from "@/components/BerufsgruppeWahlField.vue";
Vue.component('Spinner', Spinner)
Vue.component("fieldListField", ListField);
Vue.component("fieldApListField", apListField);
Vue.component("fieldGruppenListField", GruppenListField);
Vue.component("fieldKundenListField", kundenListField);
Vue.component("fieldBerufsgruppeWahlField", berufsgruppeWahlField)
Vue.use(VueFormGenerator);
Vue.config.productionTip = false
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
